/* eslint-disable no-debugger */
import { useMutation } from "@tanstack/react-query"
import { postData } from "api"
import { ErrorType } from "api/types"

const useFormRefund = () => {
  const { mutate, isLoading, data } = useMutation<any, ErrorType, any>(
    ({ url, data }) => postData<any, any>(url, data),
    {
      onSuccess: (data) => {
        console.log("DATA", data)
      },
      onError: () => {
        console.log("error")
      },
    }
  )

  return {
    mutate,
    isLoading,
    data,
  }
}

export default useFormRefund
