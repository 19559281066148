import { useMutation } from "@tanstack/react-query"
import { getData } from "api"
import { ErrorType } from "api/types"
import { IPaymentDetail } from "./types"

const usePaymentDetail = () => {
  const { mutate, isLoading, data, error } = useMutation<any, ErrorType, any>(
    ({ url, data }) => getData<IPaymentDetail, any>(url, data)
  )

  return { isLoading, data, error, mutate }
}

export default usePaymentDetail
