import { Box, Grid, styled, Button, Typography } from "@mui/material"
import palette from "styles/theme/palette"

export const FilterSection = styled(Grid)`
  background-color: ${palette.common.white};
  padding-bottom: 15px;
  padding-inline: 22px;
`

export const StyledTableContainer = styled(Box)(({ theme: { palette } }) => ({
  padding: "20px 20px 60px 20px",
  backgroundColor: palette.common.white,
  borderRadius: 5,
  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.08)",
  marginBottom: 41,
}))

export const StyledButton = styled(Button)(() => ({
  border: "1px solid #C5C5C5",
  color: "#383838",
  fontWeight: 600,
  fontSize: 12,
  lineHeight: "14px",
}))

export const StyledTextViewDetail = styled(Typography)(
  ({ theme: { palette } }) => ({
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "14.32px",
    color: palette.common.black,
    textDecorationLine: "underline",
  })
)
