import { useGetList } from "hooks/common"

import { MENU_CONFIG } from "configs/menu"
import { IPaymentSummaryResponse } from "./types"

const useGetPaymentSummary = () => {
  const { data, isLoading, refetch, error } =
    useGetList<IPaymentSummaryResponse>({
      menu: MENU_CONFIG.dashboard.page,
      url: "/admin/payment/summary",
    })

  return { data, isLoading, refetch, error }
}

export default useGetPaymentSummary
