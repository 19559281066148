import { useMemo } from "react"
import currency from "currency.js"
import _ from "lodash"
import {
  FormProvider,
  SubmitHandler,
  useForm,
  Controller,
} from "react-hook-form"
import { Modal, Grid, SelectChangeEvent, TextField } from "@mui/material"
import { SubmitButton } from "components"
import { IPaymentListData } from "pages/Customer/hooks/types"
import useFormRefund from "pages/Customer/hooks/useFormRefund"
import { ReactComponent as IcClose } from "assets/icons/ic_close.svg"
import { FORM_OPTIONS } from "configs/form/paymentRefund"
import {
  DATA_PAYMENT_COMPANY_OPTIONS,
  DATA_PAYMENT_STATE_OPTIONS,
} from "constants/data"
import palette from "styles/theme/palette"
import { mapVehicleToValue } from "utils/payment"
import {
  StyleCloseBox,
  StyledModal,
  StyledBodyBox,
  StyledTitle,
  StyledBoldTitle,
  StyledTitleContent,
  StyledTitleTable,
  StyledTextTable,
  StyledTitleInput,
  StyledTitleInputError,
  RowContainer,
} from "./styles"
import { IFormValues } from "./types"
import SearchSelect from "../SearchSelect"

interface Props {
  open: boolean
  onClose: () => void
  paymentData: IPaymentListData | undefined
  onRefunded: () => void
}

function FormRefund({ open, onClose, paymentData, onRefunded }: Props) {
  const methods = useForm<IFormValues>(FORM_OPTIONS)

  const { mutate } = useFormRefund()

  const onSort = (e: SelectChangeEvent<string | any>) => {
    methods.setValue("company", e.target.value)
  }

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // if (e.key === "Enter") {
    //   onSubmit(e)
    // }
  }

  const handleClosePopup = () => {
    methods.reset()
    onClose()
  }

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    if (paymentData?.vehicleId) {
      mutate(
        {
          url: `${process.env.REACT_APP_BASE_URL}admin/refund`,
          data: {
            vehicleId: paymentData?.vehicleId,
            company: data.company,
            manager: data.manager,
            note: data.note,
          },
        },
        {
          onError: () => {},
          onSuccess: () => {
            handleClosePopup()
            onRefunded()
          },
        }
      )
    }
  }

  const isEnableBtn = useMemo(() => {
    const validateCompany = methods.watch("company")?.length > 1
    const validateManager = methods.watch("manager")?.length > 1
    const validateNote = methods.watch("note")?.length > 2

    return validateCompany && validateManager && validateNote
  }, [
    methods.watch("company"),
    methods.watch("manager"),
    methods.watch("note"),
  ])

  return (
    <Modal open={open}>
      <FormProvider {...methods}>
        <StyledModal component="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <StyleCloseBox>
            <IcClose
              onClick={handleClosePopup}
              style={{
                cursor: "pointer",
              }}
            />
          </StyleCloseBox>
          <StyledBodyBox container>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="flex-end"
              sx={{
                borderBottomWidth: 2,
                borderBottomColor: "#000000",
                borderBottomStyle: "solid",
                paddingBottom: "10px",
              }}
            >
              <StyledBoldTitle>환불</StyledBoldTitle>
              <StyledTitle>
                환불은 라이딩이 시작되기 전까지만 가능합니다.
              </StyledTitle>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: "16px",
              }}
            >
              <StyledTitleContent>기기 정보</StyledTitleContent>
            </Grid>
            <RowContainer container>
              <Grid item>
                <StyledTitleTable>날짜</StyledTitleTable>
              </Grid>
              <Grid item>
                <StyledTextTable>
                  {_.get(paymentData, "date", "-")}
                </StyledTextTable>
              </Grid>
            </RowContainer>

            <RowContainer container>
              <Grid item>
                <StyledTitleTable>주문번호</StyledTitleTable>
              </Grid>
              <Grid item>
                <StyledTextTable>
                  {_.get(paymentData, "orderId", "-")}
                </StyledTextTable>
              </Grid>
            </RowContainer>

            <RowContainer container>
              <Grid item>
                <StyledTitleTable>기기 종류</StyledTitleTable>
              </Grid>
              <Grid item>
                <StyledTextTable>
                  {mapVehicleToValue(_.get(paymentData, "type", "-"))}
                </StyledTextTable>
              </Grid>
            </RowContainer>

            <RowContainer container>
              <Grid item>
                <StyledTitleTable>모델명</StyledTitleTable>
              </Grid>
              <Grid item>
                <StyledTextTable>
                  {_.get(paymentData, "model", "-")}
                </StyledTextTable>
              </Grid>
            </RowContainer>

            <RowContainer container>
              <Grid item>
                <StyledTitleTable>모델 번호</StyledTitleTable>
              </Grid>
              <Grid item>
                <StyledTextTable>
                  {paymentData?.vehicleId ? paymentData?.vehicleId : "-"}
                </StyledTextTable>
              </Grid>
            </RowContainer>

            <RowContainer container>
              <Grid item>
                <StyledTitleTable>회원 번호</StyledTitleTable>
              </Grid>
              <Grid item>
                <StyledTextTable>
                  {_.get(paymentData, "userId", "-")}
                </StyledTextTable>
              </Grid>
            </RowContainer>

            {/* <RowContainer container>
              <Grid item>
                <StyledTitleTable>구매 개수</StyledTitleTable>
              </Grid>
              <Grid item>
                <StyledTextTable>
                  {_.get(paymentData, "paymentState", "FAIL") === "SUCCESS"
                    ? "Y"
                    : "N"}
                </StyledTextTable>
              </Grid>
            </RowContainer> */}

            <RowContainer container>
              <Grid container>
                <Grid item>
                  <StyledTitleTable>결제 금액</StyledTitleTable>
                </Grid>
                <Grid item>
                  <StyledTextTable>
                    신용카드 -{" "}
                    {currency(paymentData?.amount || 0)
                      .format({
                        symbol: "",
                      })
                      .slice(0, -3)}
                    원
                  </StyledTextTable>
                </Grid>
              </Grid>
            </RowContainer>

            <RowContainer container>
              <Grid item>
                <StyledTitleTable>정산 여부</StyledTitleTable>
              </Grid>
              <Grid item>
                <StyledTextTable>
                  {_.get(paymentData, "placeState", "-")}
                </StyledTextTable>
              </Grid>
            </RowContainer>

            <Grid
              container
              sx={{
                paddingBottom: "20px",
                borderBottom: "1px solid #EAEAEA",
              }}
            >
              <Grid item>
                <StyledTitleTable>환불 가능 여부</StyledTitleTable>
              </Grid>
              <Grid item>
                <StyledTextTable>
                  {_.get(paymentData, "ridingState", "-")}
                </StyledTextTable>
              </Grid>
            </Grid>

            <StyledTitleInput>
              환불 관리자 정보 입력
              <StyledTitleInputError>*필수</StyledTitleInputError>
            </StyledTitleInput>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Grid item>
                <SearchSelect
                  name="company"
                  onChange={onSort}
                  dataList={DATA_PAYMENT_COMPANY_OPTIONS}
                  placeholder="회사 선택"
                  value={methods.getValues("company")}
                  style={{
                    minWidth: "240px",
                  }}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="manager"
                  control={methods.control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{
                        width: "184px",
                        "& input": {
                          fontSize: "13px",
                          lineHeight: "15.6px",
                          color: palette.common.black,
                        },
                      }}
                      placeholder="관리자 성명"
                      value={value}
                      onChange={onChange}
                      InputLabelProps={{ shrink: false }}
                      inputProps={{ "aria-label": "search keyword" }}
                      label=""
                      size="small"
                      variant="outlined"
                      onKeyDown={onEnter}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <StyledTitleInput>
              환불 사유
              <StyledTitleInputError>*필수</StyledTitleInputError>
            </StyledTitleInput>
            <Grid item xs={12}>
              <Controller
                name="note"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    id="fullWidth"
                    maxRows={6}
                    onChange={onChange}
                    value={value}
                    sx={{
                      "& textarea": {
                        fontSize: "13px",
                        lineHeight: "15.6px",
                        color: palette.common.black,
                      },
                      "& .MuiInputBase-root": {
                        height: "108px",

                        "& .MuiInputBase-input": {
                          height: "100% !important",
                        },
                      },
                    }}
                    multiline
                  />
                )}
              />
            </Grid>
          </StyledBodyBox>
          <Grid
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            style={{
              padding: "20px 24px 24px 24px",
              backgroundColor: "#F7F7F7",
            }}
          >
            <SubmitButton
              text="취소"
              style={{
                width: 102,
              }}
              onClick={handleClosePopup}
            />
            <SubmitButton
              text="확인"
              variant="contained"
              type="submit"
              style={{
                marginLeft: 8,
                width: 102,
              }}
              disabled={!isEnableBtn}
            />
          </Grid>
        </StyledModal>
      </FormProvider>
    </Modal>
  )
}

export default FormRefund
