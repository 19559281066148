import React from "react"

import { shallowEqual } from "react-redux"
import {
  Box,
  Checkbox,
  Grid,
  Pagination,
  PaginationItem,
  PaginationRenderItemParams,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material"

import { SearchType, setSearchFilters } from "slices/searchSlice"

import { useAppDispatch, useAppSelector } from "hooks/redux"
import { useSearch } from "hooks/search"

import { SearchSelect, SubmitButton, TableLoader } from "components"
import { DataRetrieved, StyledTablePaper } from "components/Table/styles"
import { ITable } from "components/Table/types"
import DashboardData from "pages/Dashboard/List/components/TableCells"
import { ReactComponent as IcLast } from "assets/icons/ic_last.svg"
import { ReactComponent as IcNext } from "assets/icons/ic_next.svg"
import IcPage from "assets/icons/ic_page_border.svg"
import { ReactComponent as IcCheckboxBorder } from "assets/images/checkbox_border_default.svg"

import { HEAD_CELL } from "configs/table"
import { DATA_PAGESIZE_TABLE } from "constants/data"
import palette from "styles/theme/palette"
import {
  StyledTableCell,
  StyledTableSortLabel,
  StyledContainerEmptyTable,
} from "./styles"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = "asc" | "desc"

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EmptyTable() {
  return (
    <StyledContainerEmptyTable>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "21px",
          color: palette.text.label,
        }}
      >
        검색된 데이터가 없습니다.
      </Typography>
    </StyledContainerEmptyTable>
  )
}

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
  headerData: any[]
}

interface EnhancedTableToolbarProps {
  numSelected: number
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headerData,
  } = props
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead
      sx={{
        borderTopWidth: 1,
        borderTopColor: palette.color.gray65,
        borderTopStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: palette.color.gray65,
        borderBottomStyle: "solid",
      }}
    >
      <TableRow>
        {headerData?.map((head) => (
          <StyledTableCell
            key={head.id}
            style={{ borderBottom: "none", background: palette.color.gray25 }}
            align={head.align || "center"}
            width={head.width}
          >
            {head.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function AdvancedTable({
  isLoading,
  headerData,
  data = [],
  mapValueRow,
  total = 0,
  showPaginate = true,
  onExportExcel,
}: ITable) {
  const dispatch = useAppDispatch()
  const { page, pageSize } = useAppSelector((state) => state.search)
  const { page: menu } = useAppSelector((state) => state.menu)
  const [order, setOrder] = React.useState<Order>("asc")
  const [orderBy, setOrderBy] = React.useState<any>("calories")
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const searchState = useAppSelector((state) => state.search, shallowEqual)
  const handleSearch = useSearch({ menu })

  const handlePage = (paging: SearchType) => {
    dispatch(setSearchFilters(paging))
    handleSearch(paging)
  }

  const onPageChange = (_event: React.ChangeEvent<unknown>, page: number) =>
    handlePage({ page, pageSize })

  const onRowsPerPageChange = (event: SelectChangeEvent<unknown>) =>
    handlePage({ page: 1, pageSize: event.target.value as number })

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => String(n.id))
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  const handleSearchFilter = (obj: SearchType) => {
    // 새로 검색할 경우 페이지 번호 초기화하기
    if (+searchState.page > 1) {
      Object.assign(obj, { page: 1 })
    }

    // dateTerm 설정
    if (menu === "Dashboard") {
      obj.dashboardDateTerm = obj.dateTerm
      delete obj.dateTerm
    }

    dispatch(setSearchFilters(obj))
    handleSearch(obj)
  }

  const onSort = (e: SelectChangeEvent<string | unknown>) => {
    handleSearchFilter({ [e.target.name]: e.target.value })
  }

  const isEmptyTable = data.length === 0

  return (
    <StyledTablePaper>
      <Grid
        container
        // alignItems="center"
        justifyContent="space-between"
        marginBottom="15px"
      >
        <DataRetrieved item>
          검색된 데이터 <span> {total}</span>건
        </DataRetrieved>
        {!isEmptyTable && (
          <Grid item>
            <SearchSelect
              dataList={DATA_PAGESIZE_TABLE}
              name="pageSize"
              onChange={onSort}
              style={{
                height: "40px",
                minWidth: 140,
              }}
            />
          </Grid>
        )}
      </Grid>

      {isEmptyTable ? (
        <EmptyTable />
      ) : (
        <>
          <TableContainer>
            <Table
              size="medium"
              aria-label={`${menu.toLowerCase()} table`}
              aria-labelledby={`${menu.toLowerCase()}Table`}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                headerData={headerData}
              />
              <TableBody>
                {isLoading ? (
                  <TableLoader colSpan={9} />
                ) : total === 0 ? (
                  <TableLoader type="empty" colSpan={9} />
                ) : (
                  stableSort(data, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(String(row.id))
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, String(row.id))
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {headerData?.map((head) => (
                            <TableCell
                              key={head.id}
                              align={head.align || "center"}
                              sx={{
                                borderBottomWidth: 1,
                                borderBottomColor: palette.color.gray65,
                                borderBottomStyle: "solid",
                                color: palette.common.black,
                                lineHeight: "14.32px",
                              }}
                            >
                              {mapValueRow?.(head, row, index) ||
                                row[head.id] ||
                                "-"}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    }
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justifyContent="flex-end" marginTop="15px">
            <SubmitButton
              text="엑셀 다운로드"
              variant="contained"
              onClick={onExportExcel}
              style={{
                width: 111,
                height: 40,
              }}
            />
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Pagination
                count={Math.ceil(total / pageSize)}
                // variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
                onChange={onPageChange}
                page={searchState.page}
                renderItem={(args: PaginationRenderItemParams) => {
                  const stylesItem: any = {}
                  switch (args.type) {
                    case "first":
                      stylesItem.transform = "rotate(180deg)"
                      break
                    case "previous":
                      stylesItem.marginLeft = "-10px"
                      stylesItem.marginRight = "10px"
                      stylesItem.transform = "rotate(180deg)"
                      break
                    case "page":
                      // stylesItem.marginLeft = "7.5px"
                      // stylesItem.marginRight = "7.5px"
                      stylesItem.color = args.selected
                        ? palette.common.black
                        : palette.color.gray400
                      stylesItem.width = 34
                      stylesItem.height = 34
                      stylesItem.backgroundImage = args.selected
                        ? `url(${IcPage})`
                        : ""
                      stylesItem.backgroundPosition = "center"
                      stylesItem.backgroundSize = "contain"
                      break
                    case "next":
                      stylesItem.marginRight = "-7px"
                      stylesItem.marginLeft = "10px"
                      break

                    default:
                      stylesItem.marginLeft = "0px"
                      break
                  }

                  return (
                    <PaginationItem
                      slots={{
                        first: () => <IcLast />,
                        previous: () => <IcNext />,
                        next: () => <IcNext />,
                        last: () => <IcLast />,
                      }}
                      {...args}
                      sx={stylesItem}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {/* {!isLoading && total > 0 && showPaginate && (
        <Pagination
          count={total}
          page={page > 0 ? page - 1 : 0}
          rowsPerPage={pageSize || 10}
          onPageChange={onPageChange}
          // onRowsPerPageChange={onRowsPerPageChange}
        />
      )} */}
    </StyledTablePaper>
  )
}

export default AdvancedTable
