import { useMutation } from "@tanstack/react-query"
import { getData } from "api"
import { ErrorType } from "api/types"
import exportToSpreadsheet from "utils/excel"
import { mapPaymentStateToValue, mapVehicleToValue } from "utils/payment"
import { IPaymentListData } from "./types"

const useExportExcel = () => {
  const { mutate, isLoading } = useMutation<any, ErrorType, any>(
    ({ url, data }) => getData<any, any>(url, data),
    {
      onSuccess: (data) => {
        if (data?.data && Array.isArray(data.data))
          exportToSpreadsheet(
            data.data.map((item: IPaymentListData) => {
              return {
                date: item.date, // 1
                orderId: item.orderId, // 2
                type: mapVehicleToValue(item.type), // 3
                model: item.model, // 4
                vehicleId: item.vehicleId, // 5
                userId: item.userId, // 6
                amount: item.amount, // 7
                paymentState: mapPaymentStateToValue(item.paymentState), // 8
                settlementState: item.settlementState, // 9
                refundState: item.refundState,
                investmentVehicleIndex: item.rentalVehicleIndex,
              }
            }),
            [
              "날짜", // 1
              "주문번호", // 2
              "기기 종류", // 3
              "모델명", // 4
              "모델번호", // 5
              "회원번호", // 6
              "결제 금액", // 7
              "결제 여부", // 8
              "정산 여부", // 9
              "환불 가능 여부", // 10
            ],
            [
              "date", // 1
              "orderId", // 2
              "type", // 3
              "model", // 4
              "vehicleId", // 5
              "userId", // 6
              "amount", // 7
              "paymentState", // 8
              "settlementState", // 9
              "refundState", // 10
            ],
            "payment"
          )
      },
      onError: () => {},
    }
  )

  return { mutate, isLoading }
}

export default useExportExcel
