import { Avatar, Box, Button, Grid, styled } from "@mui/material"
import customTheme from "styles/theme/customTheme"
import palette from "styles/theme/palette"

export const CustomerEditContainer = styled(Box)`
  background-color: ${palette.common.white};
  height: calc(100vh - 240px - 36px);
`

export const CustomerEditGrid = styled(Grid)`
  background-color: ${palette.common.white};
  padding: 10px 15.5px;
  & > div {
    border: 0.8px solid #eaeaea;
    box-shadow: 0px 5px 10px 7.3347px rgba(34, 34, 34, 0.06);
    border-radius: 8.80164px;
  }
`

export const CustomerAvatar = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 231px;
  flex-direction: column;
  row-gap: 15px;
`
export const AvatarImg = styled(Avatar)`
  height: 127px;
  width: 127px;
  background-color: #c6c6c6;
  border-radius: 50%;
`

export const CustomerAvatarBtn = styled(Button)`
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
  font-weight: 700;
  &:hover {
    background: none;
  }
`

export const CustomerForm = styled(Grid)`
  padding: 25px 20px 0 30px;
`

export const CustomerRow = styled(Grid)`
  &:first-of-type {
    margin-top: 0;
  }
  & > div > label {
    font-weight: 700;
  }
  & > div:nth-of-type(2) {
    position: relative;
    display: flex;
    align-items: center;
    & > p:nth-child(2) {
      position: absolute;
      left: calc(100% + 10px);
      color: ${customTheme.red.razzmatazz};
    }
  }
`

export const CustomerActions = styled(Grid)`
  padding-bottom: 25px;
  padding-right: 20px;
`
