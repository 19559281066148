/* eslint-disable import/prefer-default-export */
import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  minHeight: 60,
  paddingBlock: 15.5,
  paddingLeft: 30,
  backgroundColor: palette.common.white,
  marginBottom: 10,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  // borderBottom: `2px solid ${palette.background.default}`,
  "& a": {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& svg": {
      // marginRight: 20,
    },
  },
}))
