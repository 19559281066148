import { Link } from "react-router-dom"

import { Typography } from "@mui/material"

import { StyledBox } from "layout/PageHeader/styles"
import { IPageHeader } from "layout/PageHeader/types"
import { ReactComponent as IcChevronLeft } from "assets/icons/ic_chevron_left.svg"

function PageHeader({ text, path, desc }: IPageHeader) {
  return (
    <StyledBox>
      {path ? (
        <>
          <Link to={path}>
            <IcChevronLeft />
            <Typography
              variant="h3"
              component="h3"
              sx={{
                marginLeft: "6px",
              }}
            >
              {text}
            </Typography>
          </Link>
          {desc && <Typography pl={2.5}>{desc}</Typography>}
        </>
      ) : (
        <>
          <Typography variant="h3" component="h3">
            {text}
          </Typography>
          {desc && <Typography pl={2.5}>{desc}</Typography>}
        </>
      )}
    </StyledBox>
  )
}

export default PageHeader
