import {
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"

import { SearchType, setSearchFilters } from "slices/searchSlice"

import { useAppDispatch, useAppSelector } from "hooks/redux"
import { useSearch } from "hooks/search"

import { Pagination, TableLoader } from "components"
import { StyledTablePaper } from "components/Table/styles"
import { ITable, ITableHead } from "components/Table/types"
import palette from "styles/theme/palette"

function NormalTable({
  isLoading,
  headerData,
  data = [],
  mapValueRow,
  total = 0,
  showPaginate = true,
}: ITable) {
  const dispatch = useAppDispatch()
  const { page, pageSize } = useAppSelector((state) => state.search)
  const { page: menu } = useAppSelector((state) => state.menu)

  const handleSearch = useSearch({ menu })

  const handlePage = (paging: SearchType) => {
    dispatch(setSearchFilters(paging))
    handleSearch(paging)
  }

  const onPageChange = (newPage: number) =>
    handlePage({ page: newPage, pageSize })

  const onRowsPerPageChange = (event: SelectChangeEvent<unknown>) =>
    handlePage({ page: 1, pageSize: event.target.value as number })

  return (
    <StyledTablePaper sx={{ overflow: "scroll" }}>
      <TableContainer>
        <Table
          size="medium"
          style={{ width: "auto", minWidth: "0" }}
          aria-label={`${menu.toLowerCase()} table`}
          aria-labelledby={`${menu.toLowerCase()}Table`}
        >
          <TableHead
            sx={{
              borderTopWidth: 1,
              borderTopColor: "#EAEAEA",
              borderTopStyle: "solid",
              borderBottomColor: "#EAEAEA",
              borderBottomStyle: "solid",
            }}
          >
            <TableRow>
              {headerData?.map((head: ITableHead) => (
                <TableCell
                  key={head.id}
                  align={head.align || "center"}
                  style={{
                    minWidth: head.width,
                    width: head.width,
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "14.32px",
                    color: palette.text.label,
                    backgroundColor: palette.color.gray25,
                  }}
                  sx={{
                    padding: "16px",
                  }}
                >
                  {head.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader colSpan={9} />
            ) : total === 0 ? (
              <TableLoader type="empty" colSpan={9} />
            ) : (
              data?.map((row, index) => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  {headerData?.map((head) => (
                    <TableCell
                      key={`row-${head.id}`}
                      align={head.align || "center"}
                      sx={{
                        borderBottomWidth: 1,
                        borderBottomColor: "#EAEAEA",
                        borderBottomStyle: "solid",
                        color: palette.common.black,
                      }}
                    >
                      {mapValueRow?.(head, row, index) || row[head.id] || "-"}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && total > 0 && showPaginate && (
        <Pagination
          count={total}
          page={page > 0 ? page - 1 : 0}
          rowsPerPage={pageSize || 10}
          onPageChange={onPageChange}
          // onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </StyledTablePaper>
  )
}

export default NormalTable
