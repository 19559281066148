import { createRef } from "react"
import { TimeoutDialogRef } from "./types"

export default class TimeoutDialogHandler {
  static ref = createRef<TimeoutDialogRef>()

  static open() {
    if (this.ref.current) {
      this.ref.current.onOpen()
    }
  }

  static close() {
    if (this.ref.current) {
      this.ref.current.onClose()
    }
  }
}
