import { FormProvider, useForm } from "react-hook-form"
import { Link, useHistory, useParams } from "react-router-dom"
import { Avatar, Box, Button, FormLabel, Grid } from "@mui/material"
import { useMessage } from "hooks/modal"
import { Input, RadioButton, Textarea } from "components"
import { PageHeader } from "layout"
import {
  ProductEditActions,
  ProductEditAvatar,
  ProductEditContainer,
  ProductEditInfo,
} from "./styles"
import { IProductEdit } from "./types"

const ProductEdit = () => {
  const history = useHistory()
  const { projectId, productId } = useParams<{
    projectId: string
    productId: string
  }>()
  const methods = useForm()
  const handleMessage = useMessage()

  const backLink = `/qr/project/${projectId}/product/${productId}`

  const formInputs: IProductEdit[] = [
    {
      label: "상품명(NFT명)",
      value: "tootoo denim",
      type: "text",
      isRequired: true,
    },
    {
      label: "상품설명(NFT설명)",
      value:
        "코튼 100% 소재의 와이드핏 데님팬츠 스타일로 13~13.5oz의 원단을 사용하여 다른 데님보다 조금 중량이 더 나가며 두께감이 있어서 와이드 실루엣을 더 잘 살려줍니다. 다양한 상의에 모두 코디 가능하며 오리지널한 워싱 무드 연출을 위해 핸드 브러쉬 가공을 적용하여 자연스러운 데님 컬러가 돋보이는 것이 특징입니다",
      type: "textarea",
      isRequired: true,
    },
    {
      label: "브랜드명",
      value: "tootoo denim",
      type: "input",
    },
    {
      label: "제품제조번호",
      value: "20231004",
      type: "input",
    },
  ]

  const selectInput = {
    label: "수정 범위",
    value: 0,
    type: "radio",
    options: [
      { label: "그룹 전체 수정", value: 0 },
      { label: "이 QR코드만 수정", value: 1 },
    ],
  }

  const renderForm = () => {}

  const onSubmit = (data: any) => {
    console.log(1)
    const message =
      data?.modifyType === "1"
        ? "그룹 전체 상품 정보를 수정하시겠습니까?\n상품 그룹 하위의 모든 QR코드가 입력하신\n정보로 변경됩니다."
        : "이 QR코드의 상품 정보만 수정하시겠습니까?\n수정 시 상품 그룹 정보가 변경됩니다."
    handleMessage({
      title: " ",
      message,
      type: "confirm",
    })
  }

  return (
    <FormProvider {...methods}>
      <PageHeader text="상품정보 수정" path={backLink} />
      <ProductEditContainer container>
        <ProductEditAvatar>
          <Avatar src={require("assets/images/img_product.png")} />
          <Button variant="text">상품 이미지 등록</Button>
        </ProductEditAvatar>
        <ProductEditInfo
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Grid item xs={8} container direction="column" gap={1}>
            {formInputs.map(({ label, value, type, isRequired }, idx) => (
              <Grid
                key={`product_info-${idx}`}
                container
                alignItems="center"
                columnSpacing={1}
              >
                <Grid item xs={3}>
                  {type === "input" ? (
                    <Input name="2" value={label} />
                  ) : (
                    <FormLabel required={isRequired}>{label}</FormLabel>
                  )}
                </Grid>
                <Grid item xs={9}>
                  {type === "textarea" ? (
                    <Textarea name="3" value={value} />
                  ) : (
                    <Input name="2" fullWidth value={value} />
                  )}
                </Grid>
              </Grid>
            ))}
            <Button color="blue" variant="contained">
              속성 추가
            </Button>
            <Box mt={1.5}>
              <FormLabel>{selectInput.label}</FormLabel>
              <RadioButton name="modifyType" options={selectInput.options} />
            </Box>
          </Grid>
          <ProductEditActions
            item
            xs={4}
            container
            alignItems="flex-end"
            justifyContent="flex-end"
            gap={1.25}
          >
            <Link to={backLink}>
              <Button variant="outlined">변경 취소</Button>
            </Link>
            <Button type="submit" color="blue" variant="contained">
              수정사항 저장
            </Button>
          </ProductEditActions>
        </ProductEditInfo>
      </ProductEditContainer>
    </FormProvider>
  )
}

export default ProductEdit
