import { shallowEqual } from "react-redux"

import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material"
import { Grid, Typography } from "@mui/material"

import { useAppSelector } from "hooks/redux"

import {
  StyledSelect,
  StyledMenuItem,
} from "components/SearchInputs/SearchSelect/styles"
import { ISearchSelect } from "components/SearchInputs/SearchSelect/types"

import { CaptionType, SEARCH_CAPTION } from "configs/search"

function SearchSelect({
  caption = false,
  value = "",
  dataList = [],
  name,
  onChange,
  isChangeColor,
  style,
}: ISearchSelect) {
  const searchState = useAppSelector((state) => state.search, shallowEqual)
  return (
    <Grid item xs="auto">
      {caption && (
        <Typography component="p" variant="caption">
          {SEARCH_CAPTION[name as CaptionType]}
        </Typography>
      )}
      <StyledSelect
        name={name}
        style={style}
        value={
          name === "searchType" || name === "paymentState"
            ? value
            : searchState[name]
        }
        size="small"
        displayEmpty
        IconComponent={KeyboardArrowDownIcon}
        onChange={onChange}
        isChangeColor={!!isChangeColor}
        sx={{
          fontSize: "13px",
        }}
      >
        {dataList?.map(({ label, value }) => (
          <StyledMenuItem key={`menu-item-${label}`} value={value}>
            {label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </Grid>
  )
}

export default SearchSelect
