import styled from "@emotion/styled"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"

export const StyledDialogContent = styled(DialogContent)(() => ({
  paddingTop: 52,
  paddingBottom: 22,
  width: 381,
}))

export const StyledDialogContentText = styled(DialogContentText)(() => ({
  paddingBottom: 32,
  fontSize: 15,
  textAlign: "center",
}))

export const StyledDialogActions = styled(DialogActions)(() => {
  return {
    justifyContent: "center",
    padding: 0,
  }
})

export const StyledButton = styled(Button)(() => ({
  width: 145,
}))
