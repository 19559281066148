/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Grid } from "@mui/material"
import { AppProgressType, setPageLoading } from "slices/menuSlice"
import { useMenu } from "hooks/common"
import { useAppDispatch, useAppSelector } from "hooks/redux"
import { Table } from "components"
import { ITableHead } from "components/Table/types"
import FormSearch from "pages/Customer/components/FormSearch"
import { numberWithCommas } from "utils"
import { MENU_CONFIG } from "configs/menu"
import {
  isDisableRefundButton,
  isPaymentStateSuccess,
  mapPaymentStateToValue,
  mapVehicleToValue,
} from "utils/payment"
import FormRefund from "./components/FormRefund"

import { IPaymentListData } from "./hooks/types"
import useExportExcel from "./hooks/useExportExcel"
import useGetPaymentList from "./hooks/useGetPaymentList"
// import useGetPayments from "./hooks/useGetPayments"
import {
  StyledTableContainer,
  StyledButton,
  StyledTextViewDetail,
} from "./styles"

const Customer = () => {
  const [open, setOpen] = useState<boolean>(false)
  useMenu(MENU_CONFIG.customer)
  const [paymentData, setPaymentData] = useState<IPaymentListData>()
  const dispatch = useAppDispatch()
  const searchState = useAppSelector((state) => state.search)
  const location = useLocation()
  const headerData: ITableHead[] = [
    {
      id: "id",
      label: "No.",
      width: "5%",
    },
    {
      // 1
      id: "date",
      label: "날짜",
    },
    {
      // 2
      id: "orderId",
      label: "주문번호",
    },
    {
      // 3
      id: "type",
      label: "기기 종류",
    },
    {
      // 4
      id: "model",
      label: "모델명",
    },
    {
      // 5
      id: "vehicleId",
      label: "모델번호",
    },
    {
      // 6
      id: "userId",
      label: "회원번호",
    },
    {
      // 7
      id: "amount",
      label: "결제 금액",
    },
    {
      // 8
      id: "paymentState",
      label: "결제 여부",
    },
    {
      // 9
      id: "settlementState",
      label: "정산 여부",
    },
    {
      // 10
      id: "refundState",
      label: "환불 가능 여부",
    },
    {
      // 11
      id: "refund",
      label: "환불",
    },
    {
      id: "viewContent",
      label: "내용 보기",
      width: "10%",
    },
  ]

  const handlePopupOpen = (data: IPaymentListData) => {
    setOpen(true)
    setPaymentData(data)
  }

  const mapValueRow = (header: ITableHead, item: any, idx: number) => {
    switch (header.id) {
      case "id":
        return (searchState.page - 1) * searchState.pageSize + idx + 1
      case "type": {
        return mapVehicleToValue(item?.type)
      }
      case "amount":
        return `${numberWithCommas(item[header.id])}원`
      case "paymentState": {
        return mapPaymentStateToValue(item?.paymentState)
      }
      case "settlementState":
      case "refundState": {
        return isPaymentStateSuccess(item?.paymentState) ? item[header.id] : "-"
      }
      case "refund":
        return (
          <StyledButton
            variant="outlined"
            onClick={() => handlePopupOpen(item)}
            disabled={isDisableRefundButton(
              item?.paymentState,
              item?.vehicleId
            )}
          >
            환불하기
          </StyledButton>
        )
      case "viewContent":
        return (
          <Link
            to={`/customer/detail/${item.rentalVehicleIndex}${location.search}`}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              columnGap={1.25}
            >
              <StyledTextViewDetail>자세히 보기</StyledTextViewDetail>
            </Grid>
          </Link>
        )

      default:
        return item[header.id]
    }
  }

  const { data, isLoading, refetch } = useGetPaymentList()

  const dataTable = (data as any)?.data
  const total = (data as any)?.total

  useEffect(() => {
    if (isLoading) {
      dispatch(setPageLoading(AppProgressType.LOADING))
    } else {
      dispatch(setPageLoading(AppProgressType.NONE))
    }
  }, [isLoading])

  const { mutate } = useExportExcel()

  const onExportExcel = () => {
    mutate({
      url: `${process.env.REACT_APP_BASE_URL}admin/payment/list`,
      data: {
        startDate: searchState.startDate,
        endDate: searchState.endDate,
        paymentState: searchState.paymentState,
        page: 0,
        pageSize: total,
        searchType: searchState.searchType,
      },
    })
  }

  const onCloseFromRefund = () => {
    setPaymentData(undefined)
    setOpen(false)
  }

  const onRefunded = () => {
    refetch()
  }

  return (
    <>
      <FormRefund
        open={open}
        onClose={onCloseFromRefund}
        paymentData={paymentData}
        onRefunded={onRefunded}
      />
      <FormSearch />
      <StyledTableContainer>
        <Table.Advanced
          isLoading={false}
          headerData={headerData}
          data={dataTable}
          total={total}
          mapValueRow={mapValueRow}
          showPaginate={false}
          onExportExcel={onExportExcel}
        />
      </StyledTableContainer>
    </>
  )
}

export default Customer
