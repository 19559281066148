import React, { useState } from "react"

import { shallowEqual } from "react-redux"

import { Menu as MenuIcon } from "@mui/icons-material"
import { MenuList, Typography } from "@mui/material"

import { setShowMenu } from "slices/menuSlice"

import { useAppDispatch, useAppSelector } from "hooks/redux"

import {
  StyledAppBar,
  StyledIconButton,
  StyledMenuButton,
  StyledMenuButtonText,
  StyledPopover,
  StyledToolbar,
  StyledTypography,
  StyledUserButton,
} from "layout/Header/styles"
import useLogout from "pages/Login/hooks/useLogout"
import IconArrowDown from "assets/icons/ic_arrow_down.svg"

export default function Header() {
  const dispatch = useAppDispatch()
  const { menuTitle, showSidebar } = useAppSelector(
    (state) => ({
      menuTitle: state.menu.menuTitle,
      showSidebar: state.menu.showSidebar,
    }),
    shallowEqual
  )

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? "admin-menu-popover" : undefined

  const { handleLogout } = useLogout()

  const handleLogOut = () => {
    setAnchorEl(null)
    handleLogout()
  }

  const onClick = (e: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(e.currentTarget)

  const onClose = () => setAnchorEl(null)

  return (
    <StyledAppBar position="fixed" elevation={0} open={showSidebar}>
      <StyledToolbar variant="dense" open={showSidebar}>
        <StyledTypography
          open={showSidebar}
          component="h2"
          variant="h2"
          sx={{
            lineHeight: "21.48px",
          }}
        >
          <StyledIconButton
            aria-label="open drawer"
            edge="start"
            onClick={() => dispatch(setShowMenu())}
          >
            <MenuIcon />
          </StyledIconButton>
          {menuTitle}
        </StyledTypography>
        <StyledUserButton
          aria-describedby={id}
          disableRipple
          endIcon={<img src={IconArrowDown} width={8} height={5} alt="" />}
          variant="contained"
          onClick={onClick}
        >
          <Typography
            component="p"
            variant="body2"
            textTransform="lowercase"
            sx={{
              fontWeight: 500,
              fontSize: 13,
              color: "#455A64",
            }}
          >
            {localStorage.getItem("adminInfo") || ""}
          </Typography>
        </StyledUserButton>
        <StyledPopover
          id={id}
          elevation={1}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={onClose}
        >
          <MenuList
            component="div"
            id="admin-menu"
            aria-labelledby="admin-menu-button"
            sx={{
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
          >
            <StyledMenuButton component="button" onClick={handleLogOut}>
              <StyledMenuButtonText variant="body2">
                로그아웃
              </StyledMenuButtonText>
            </StyledMenuButton>
          </MenuList>
        </StyledPopover>
      </StyledToolbar>
    </StyledAppBar>
  )
}
