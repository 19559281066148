import { Modal } from "@mui/material"
import { setVisibleModalExpired } from "slices/loginSlice"
import { useAppDispatch, useAppSelector } from "hooks/redux"
import useLogout from "pages/Login/hooks/useLogout"
import {
  StyledBox,
  StyledBoxFooter,
  StyledBoxText,
  StyledButton,
  StyledTypographyDesc,
} from "./styles"

function ModalTimeExpired() {
  const dispatch = useAppDispatch()
  const visibleModalExpired = useAppSelector(
    (state) => state.login.visibleModalExpired
  )

  const { handleLogout } = useLogout()

  const onCloseModal = () => {
    dispatch(setVisibleModalExpired(false))
    handleLogout()
  }

  return (
    <Modal open={visibleModalExpired}>
      <StyledBox>
        <StyledBoxText>
          <StyledTypographyDesc variant="h6">
            로그인 세션이 만료되었습니다.
            <br />
            다시 로그인해주세요.
          </StyledTypographyDesc>
        </StyledBoxText>
        <StyledBoxFooter>
          <StyledButton onClick={onCloseModal}>확인</StyledButton>
        </StyledBoxFooter>
      </StyledBox>
    </Modal>
  )
}

export default ModalTimeExpired
