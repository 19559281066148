import { Box, Grid, styled } from "@mui/material"
import palette from "styles/theme/palette"

export const ProductDetailContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > div:nth-of-type(2) {
    padding-top: 50px;
  }
`

export const ProductDetailCard = styled(Box)`
  background: ${palette.common.white};
  padding: 15px 30px;
`

export const ProductDetailProperty = styled(Grid)``

export const ProductDetailActions = styled(Box)`
  margin-block: 30px;
`
