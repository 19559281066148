import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"

export const DashboardTableContainer = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.common.white};
  padding: 50px 22px 30px;
`
)

export const SummaryBox = styled(Box)`
  display: flex;
  gap: 45px;
`

export const SummaryItem = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const DashboardTable = styled(Box)`
  margin-top: 40px;
`
export const QRListConainer = styled(Box)`
  & > div:last-child {
    border: none;
    padding: 0 22px 22px;
    box-shadow: none;
  }
`
