import { shallowEqual } from "react-redux"

import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material"
import { Grid, Typography } from "@mui/material"

import { useAppSelector } from "hooks/redux"

import { ISearchSelect } from "components/SearchInputs/SearchSelect/types"

import { CaptionType, SEARCH_CAPTION } from "configs/search"
import { StyledMenuItem, StyledSelect } from "./styles"

const PLACEHOLDER_VALUE = ""

function SearchSelect({
  caption = false,
  value = "",
  dataList = [],
  name,
  onChange,
  isChangeColor,
  style,
  placeholder,
}: ISearchSelect) {
  const searchState = useAppSelector((state) => state.search, shallowEqual)

  return (
    <Grid item xs="auto">
      {caption && (
        <Typography component="p" variant="caption">
          {SEARCH_CAPTION[name as CaptionType]}
        </Typography>
      )}
      <StyledSelect
        name={name}
        style={style}
        value={name === "searchCondition" ? value : searchState[name]}
        size="small"
        displayEmpty
        IconComponent={KeyboardArrowDownIcon}
        onChange={onChange}
        isChoosePlaceHolder={value === PLACEHOLDER_VALUE}
        defaultValue={placeholder ? PLACEHOLDER_VALUE : false}
      >
        {placeholder && (
          <StyledMenuItem value={PLACEHOLDER_VALUE} disabled>
            {placeholder}
          </StyledMenuItem>
        )}
        {dataList?.map(({ label, value }) => (
          <StyledMenuItem key={`menu-item-${label}`} value={value}>
            {label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </Grid>
  )
}

export default SearchSelect
