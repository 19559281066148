/* eslint-disable react/display-name */
import { forwardRef, useState, useImperativeHandle } from "react"
import Dialog from "@mui/material/Dialog"
import {
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogActions,
  StyledButton,
} from "./styles"
import { TimeoutDialogRef } from "./types"

const TimeoutDialog = forwardRef<TimeoutDialogRef, any>((_, ref) => {
  const [open, setOpen] = useState<boolean>(false)

  useImperativeHandle(
    ref,
    () => ({
      onOpen() {
        setOpen(true)
      },
      onClose() {
        setOpen(false)
      },
    }),
    []
  )

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <StyledDialogContent>
        <StyledDialogContentText id="alert-dialog-description">
          로그인 후 이용해주세요.
        </StyledDialogContentText>
        <StyledDialogActions>
          <StyledButton variant="contained">확인</StyledButton>
        </StyledDialogActions>
      </StyledDialogContent>
    </Dialog>
  )
})

export default TimeoutDialog
