import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

import {
  DATA_SEARCH_TYPE_OPTIONS,
  DATA_PAYMENT_STATE_OPTIONS,
} from "constants/data"
import { PREV_29_DAYS, TODAY } from "utils/common"

export type SearchType = {
  gender?: string
  useYn?: string
  dessert?: string
  food?: string
  sweets?: string
  drink?: string
  color?: string
  mappingStatus?: string
  inspectStatus?: string

  searchCondition?: string
  searchKeyword?: string
  paymentState?: string
  searchType?: string

  sort?: string
  page?: string | number
  pageSize?: string | number

  dateType?: string
  term?: string
  dateTerm?: string | number
  dashboardDateTerm?: string | number
  startDate?: string | null
  endDate?: string | null
}

export const initialState: SearchType = {
  useYn: "",
  gender: "",
  dessert: "",
  sweets: "",
  food: "",
  drink: "",
  color: "all",
  mappingStatus: "",
  inspectStatus: "",

  searchCondition: "",
  searchKeyword: "",

  sort: "sort",
  page: 1,
  pageSize: 10,

  dateType: "regDate",
  term: "daily",
  dateTerm: 0,
  dashboardDateTerm: 1,
  startDate: PREV_29_DAYS,
  endDate: TODAY,
  paymentState: DATA_PAYMENT_STATE_OPTIONS[0].value,
  searchType: DATA_SEARCH_TYPE_OPTIONS[0].value,
}

export type SearchKey = keyof typeof initialState

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchFilter: (state, { payload }) => {
      if (_.isArray(payload)) {
        payload.forEach((item) => {
          state[item.type as SearchKey] = item.value
        })
      } else {
        state[payload.type as SearchKey] = payload.value
      }
    },
    setSearchFilters: (state, { payload }) => {
      return { ...state, ...payload }
    },
    reset: () => initialState,
  },
})

export const { setSearchFilter, setSearchFilters, reset } = searchSlice.actions

export const searchSelector = (state: { search: SearchType }) => state.search

export default searchSlice.reducer
