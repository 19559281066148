import { useMutation } from "@tanstack/react-query"

import { useCookies } from "react-cookie"
import { useHistory } from "react-router-dom"
import { setLogOut } from "slices/loginSlice"

import { useMessage } from "hooks/modal"
import { useAppDispatch } from "hooks/redux"

import { postData } from "api"
import { ErrorType } from "api/types"
import COOKIE_NAME from "configs/cookie/cookieNames"

type ReturnType = {
  accessToken: string
  adminInfo: { email: string; index: number }
}

type BodyType = object

const useLogout = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [cookies, setCookie, removeCookie] = useCookies([
    COOKIE_NAME.COOKIE_ID,
    COOKIE_NAME.ACCESS_TOKEN,
  ])

  const handleMessage = useMessage()

  const { mutate: handleLogout, isLoading } = useMutation<
    ReturnType,
    ErrorType
  >(() => postData<ReturnType, BodyType>("/admin/logout", {}), {
    onSuccess: (data) => {
      removeCookie(COOKIE_NAME.ACCESS_TOKEN)
      localStorage.removeItem("accessToken")
      localStorage.removeItem("adminInfo")
      dispatch(setLogOut())
    },
    onError: (error: ErrorType) => {
      handleMessage(error)
    },
  })

  return { handleLogout, isLoading }
}

export default useLogout
