import { Box, Grid, styled } from "@mui/material"
import palette from "styles/theme/palette"

export const ProductEditContainer = styled(Grid)`
  margin-top: 20px;
  & > * {
    background: ${palette.common.white};
    box-shadow: 0px 5px 10px 7.3347px rgba(34, 34, 34, 0.06);
    border-radius: 8.80164px;
  }
`

export const ProductEditAvatar = styled(Grid)`
  width: 215px;
  height: 231px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  & > div {
    height: 120px;
    width: 120px;
  }
  & > button {
    text-decoration: underline;
  }
`

export const ProductEditInfo = styled(Box)`
  flex: 1;
  padding: 30px;
  display: flex;
  gap: 60px;
  & .MuiFormLabel-root {
    font-weight: 700;
  }
  & textarea {
    height: 84px;
  }
`
export const ProductEditActions = styled(Grid)`
  align-items: flex-end;
`
