import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledContainerLoading = styled(Box)(() => ({
  position: "fixed",
  width: "calc(100% - 240px)",
  height: "calc(100vh - 80px)",
  backgroundColor: "#F7F7F7",
  top: 80,
  left: 240,
  display: "none",
  justifyContent: "center",
  alignItems: "center",
}))

export const StyledLoading = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

export const StyledLoadingText = styled(Typography)(
  ({ theme: { palette } }) => ({
    fontWeight: 300,
    fontSize: 18,
    lineHeight: "21.48px",
    color: palette.text.secondary,
    marginTop: 32,
  })
)

export const StyledBoxError = styled(Box)(({ theme: { palette } }) => ({
  paddingTop: 170,
  paddingLeft: 50,
  paddingRight: 50,
  paddingBottom: 40,
  backgroundColor: palette.common.white,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

export const StyleDivider = styled(Box)(() => ({
  width: 560,
  backgroundColor: "#EAEAEA",
  height: 1,
  marginTop: 120,
  marginBottom: 24,
}))

export const StyledErrorTitle = styled(Typography)(() => ({
  fontWeight: 300,
  fontSize: 28,
  lineHeight: "33.41px",
  color: "#192124",
  textAlign: "center",
}))

export const StyledErrorBoldTitle = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 28,
  lineHeight: "33.41px",
  color: "#192124",
  marginTop: 32,
  textAlign: "center",
  display: "inline",
}))

export const StyledErrorTextDesc = styled(Typography)(
  ({ theme: { palette } }) => ({
    fontSize: 16,
    lineHeight: "19.09px",
    color: palette.text.secondary,
    textAlign: "center",
  })
)
