import { useEffect, useMemo, useState } from "react"
import dayjs from "dayjs"
import "dayjs/locale/ko"

import { Box, Grid } from "@mui/material"

import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { SubmitButton } from "components/Buttons"

import { IDatePicker } from "components/SearchInputs/DatePicker/types"

import { ReactComponent as IconDate } from "assets/icons/ic_calendar.svg"
import DashCalendar from "assets/icons/ic_dash_calendar.svg"

import { formatDate } from "utils/common"
import { helperMonthChange } from "utils/dateTime"
import { StyledDatePicker, StyledEmptyBox, StyledTitle } from "./styles"
import "react-datepicker/dist/react-datepicker.css"

function DatePicker({
  readOnly = false,
  dates = { startDate: null, endDate: null },
  term,
  onChange,
  onSubmit,
}: IDatePicker) {
  const maxDate = useMemo(() => dayjs(), [])
  useEffect(() => {
    const limiDate = dayjs().subtract(29, "day")
    if (limiDate.isAfter(dates.startDate)) {
      const newEndDate = dayjs(dates.startDate)
        .add(29, "day")
        .format("YYYY/MM/DD")
      onChange("endDate", newEndDate || null)
    } else {
      onChange("endDate", dayjs().format("YYYY/MM/DD"))
    }
  }, [dates.startDate])

  useEffect(() => {
    const newStartDate = dayjs(dates.endDate)
      .subtract(29, "day")
      .format("YYYY/MM/DD")

    onChange("startDate", newStartDate || null)
  }, [dates.endDate])

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      dateFormats={{ monthAndYear: "YYYY년 MMM" }}
      adapterLocale="ko"
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Grid item>
          <StyledTitle variant="h6">시작일</StyledTitle>
          <StyledDatePicker
            format="YYYY/MM/DD"
            value={dayjs(dates.startDate)}
            slots={{
              openPickerIcon: () => <IconDate />,
            }}
            onChange={(e) => {
              onChange("startDate", e ? formatDate(term, e) : null)
            }}
            maxDate={maxDate}
            disableFuture
            onMonthChange={helperMonthChange}
          />
        </Grid>
        <Grid item paddingX="19px">
          <StyledEmptyBox />
          <img
            src={DashCalendar}
            width={8}
            height={1}
            alt="Dash"
            style={{
              marginBottom: 11,
            }}
          />
        </Grid>
        <Grid item>
          <StyledTitle variant="h6">종료일</StyledTitle>
          <StyledDatePicker
            format="YYYY/MM/DD"
            value={dayjs(dates.endDate)}
            slots={{
              openPickerIcon: () => <IconDate />,
            }}
            onChange={(e) => {
              onChange("endDate", e ? formatDate(term, e) : null)
            }}
            maxDate={maxDate}
            onMonthChange={helperMonthChange}
          />
        </Grid>
        <Grid item>
          <Box width={18} />
        </Grid>
        <Grid item>
          <SubmitButton
            variant="contained"
            text="조회"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              fontSize: 13,
            }}
            onClick={onSubmit}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

export default DatePicker
