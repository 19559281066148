import { createSlice } from "@reduxjs/toolkit"

type LoginType = {
  isLogin: boolean
  adminType: "private" | "public"
  accessToken: string
  visibleModalExpired: boolean
  timeCount: number
}

const initialState: LoginType = {
  isLogin: false,
  adminType: "private",
  accessToken: "",
  visibleModalExpired: false,
  timeCount: 0,
}

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLogIn: (state, { payload }) => {
      state.isLogin = true
      state.accessToken = payload.accessToken
    },
    setLogOut: (state) => {
      state.isLogin = false
      localStorage.clear()
    },
    setVisibleModalExpired: (state, { payload }) => {
      state.visibleModalExpired = payload
    },
    setTimeCount: (state, { payload }) => {
      state.timeCount = payload
    },
  },
})

export const { setLogIn, setLogOut, setVisibleModalExpired, setTimeCount } =
  loginSlice.actions

export const loginSelector = (state: { login: LoginType }) => state.login

export default loginSlice.reducer
