import { Box, Grid, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import palette from "styles/theme/palette"

export const StyledButtonContainer = styled(Grid)(({ theme: { palette } }) => ({
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 20,
  "& a": {
    backgroundColor: palette.common.white,
    "&:hover": {
      backgroundColor: palette.common.white,
    },
  },
}))

export const StyledForm = styled(Box)<{ component: React.ElementType }>(
  ({ theme: { palette } }) => ({
    width: "100%",
    padding: 18,
    paddingTop: 13,
    paddingBottom: 23,
    borderRadius: 5,
    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.08)",
    backgroundColor: palette.common.white,
    marginBottom: 16,
  })
)

export const StyledLabel = styled(Box)(() => ({
  width: "100%",
  minHeight: 48,
  height: "auto",
  padding: "0px 35px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  borderRight: 0,
}))

export const StyledLongerInputContainer = styled(Box)(() => ({
  width: "100%",
  minHeight: 48,
  height: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  borderLeft: 0,
  marginBottom: 7,
  "& > button": {
    marginLeft: 7,
  },
}))

export const StyledComponentContainer = styled(Box)(() => ({
  gridColumn: "span 4",
  display: "grid",
  gridTemplateColumns: "repeat(1, 1fr)",
}))

export const StyledGridContainer = styled(Grid)(({ theme: { border } }) => ({
  alignItems: "center",
  "& > div:first-of-type": {
    minWidth: 160,
    height: "auto",
    padding: "0px 35px",
  },
  "& > div": {
    padding: "0px 20px",
  },
  "& > div:last-of-type": {
    flex: 1,
  },
  "& .MuiFormControlLabel-label": {
    fontSize: 14,
  },
}))

export const StyledSearchKeywordContainer = styled(Box)(() => ({
  gridColumn: "span 4",
  display: "grid",
  gridTemplateColumns: "160px 1fr",
}))

export const StyledInputContainer = styled(Box)(() => ({
  width: "100%",
  minHeight: 48,
  height: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  columnGap: 5,
  padding: "0 20px",
  borderLeft: 0,
}))

export const StyledSearchResultContainer = styled(Grid)(({ theme }) => ({
  background: theme.palette.common.white,
  paddingInline: 22,
  paddingTop: 20,
  justifyContent: "space-between",
  alignItems: "center",
  "& > div:first-of-type > h4": {
    fontWeight: 500,
  },
  "& > div:last-of-type": {
    columnGap: 10,
  },
}))

export const StyledTabListContainer = styled(Grid)(() => ({}))

export const StyledTabItem = styled(Box)<{ isActive: boolean }>(
  ({ isActive }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 37px",
    fontSize: 12,
    fontWeight: 700,
    background: isActive ? palette.common.white : palette.background.gainsboro,
    color: isActive ? palette.common.black : `rgba(0,0,0,0.4)`,
    cursor: "pointer",
    borderRadius: "10px 10px 0px 0px",
  })
)

export const StyledTitle = styled(Typography)(({ theme: { palette } }) => ({
  fontSize: 12,
  lineHeight: "14.32px",
  fontWeight: 400,
  marginBottom: 4,
  color: palette.text.label,
}))

export const StyledDataRetrieved = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 13,
  lineHeight: "18.82px",
}))
