import { useMutation } from "@tanstack/react-query"
import { useHistory } from "react-router-dom"
import { CookieSetOptions } from "universal-cookie"

import { setLogIn } from "slices/loginSlice"

import { useAppDispatch } from "hooks/redux"

import { postData } from "api"
import { ErrorType } from "api/types"
import { IFormValues } from "pages/Login/types"
import { DURATION_EXPIRED_ACCESS_TOKEN_COOKIE } from "configs/cookie/accessToken"
import COOKIE_NAME from "configs/cookie/cookieNames"

type ReturnType = {
  accessToken: string
  adminInfo: { email: string; index: number }
}

type BodyType = {
  email: string
  password: string
}

type PayloadType = {
  url: string
  data: BodyType
}

interface ILogin {
  formData: IFormValues
  setCookie: (
    name: any,
    value: string,
    options?: CookieSetOptions | undefined
  ) => void
  removeCookie: (name: any, options?: CookieSetOptions | undefined) => void
}

const useLogin = ({ formData, setCookie, removeCookie }: ILogin) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const { mutate, isLoading } = useMutation<ReturnType, ErrorType, PayloadType>(
    ({ url, data }) => postData<ReturnType, BodyType>(url, data),
    {
      onSuccess: (data) => {
        localStorage.setItem("adminInfo", data.adminInfo.email)
        localStorage.setItem("accessToken", data.accessToken)
        if (data.accessToken) {
          setCookie(COOKIE_NAME.ACCESS_TOKEN, "swing", {
            maxAge: DURATION_EXPIRED_ACCESS_TOKEN_COOKIE,
          })

          const { cookie, email } = formData
          if (cookie) {
            setCookie(COOKIE_NAME.COOKIE_ID, email, { maxAge: 60 * 60 * 14 })
          } else {
            removeCookie(COOKIE_NAME.COOKIE_ID)
          }

          dispatch(setLogIn(data))
          history.replace("/")
        }
      },
      onError: () => {
        // handleMessage(error)
        // TODO: DEMO 코드 제거
        // =================DEMO=================
        // const { cookie, email } = formData
        // if (cookie) {
        //   setCookie("cookieID", email, { maxAge: 60 * 60 * 24 * 14 })
        // } else {
        //   removeCookie("cookieID")
        // }
        // dispatch(setLogIn({}))
        // history.replace("/")
        // =================DEMO=================
      },
    }
  )

  return { mutate, isLoading }
}

export default useLogin
