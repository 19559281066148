import { useMemo } from "react"

import { shallowEqual } from "react-redux"

import { Grid, Button, Typography } from "@mui/material"

import { useAppSelector } from "hooks/redux"

import { SearchDatePicker, SearchSelect } from "components"
import {
  StyledButtonsContainer,
  StyledDateTermButton,
} from "components/SearchInputs/DateTermButton/styles"
import { IDateTermButton } from "components/SearchInputs/DateTermButton/types"

import { SEARCH_COMPONENT, SEARCH_DATE_TERM_PERIOD } from "configs/search"

function DateTermButton({
  caption = false,
  dates = { startDate: null, endDate: null },
  onSelect = () => {},
  onChange,
  onClick,
}: IDateTermButton) {
  const { dateTerm, dashboardDateTerm } = useAppSelector(
    (state) => ({
      dateTerm: state.search.dateTerm,
      dashboardDateTerm: state.search.dashboardDateTerm,
    }),
    shallowEqual
  )
  const menu = useAppSelector((state) => state.menu.page)
  const { dateSelect } =
    SEARCH_COMPONENT[menu as keyof typeof SEARCH_COMPONENT] || {}

  const currentTerm = useMemo(
    () => ({
      Dashboard: dashboardDateTerm,
      Chart: dateTerm,
    }),
    [dateTerm, dashboardDateTerm]
  )

  return (
    <SearchDatePicker
      caption={caption}
      term="daily"
      dates={dates}
      onChange={onChange}
    />
  )
}

export default DateTermButton
