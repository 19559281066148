import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Avatar, Typography } from "@mui/material"

import { Table } from "components"
import { ITableHead } from "components/Table/types"
import { SAMPLE_DATA_PROJECT } from "dummy"

const ProjectTable = () => {
  const location = useLocation()

  const headerData = [
    {
      id: "id",
      label: "No",
      width: "5%",
      align: "center",
    },
    {
      id: "customerCompanyName",
      label: "고객사",
      width: "10%",
      align: "left",
    },
    {
      id: "productImg",
      label: "상품 img",
      width: "10%",
    },
    {
      id: "productName",
      label: "상품명(NFT명)",
      width: "10%",
    },
    {
      id: "detailView",
      label: "상세보기",
      width: "10%",
    },
    {
      id: "productId",
      label: "상품 id",
      width: "10%",
    },
    {
      id: "QRId",
      label: "QR id",
      width: "10%",
    },
    {
      id: "QRUrl",
      label: "QR URL",
      width: "10%",
    },
    {
      id: "QRImg",
      label: "QR 이미지",
      width: "10%",
    },
    {
      id: "QRCreatedDate",
      label: "QR 생성일시",
      width: "10%",
    },
  ]

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const mapValueRow = (header: ITableHead, item: any) => {
    if (header.id === "detailView") {
      return (
        <Link to={`${location.pathname}/product/${item.id}`}>
          <Typography sx={{ textDecoration: "underline" }}>
            {item[header.id]}
          </Typography>
        </Link>
      )
    }

    if (header.id === "productImg" || header.id === "QRImg") {
      return (
        <Avatar
          variant="square"
          sx={{ height: 30, width: 30, margin: "0 auto" }}
          src={item[header.id]}
        />
      )
    }

    return item[header.id]
  }

  return (
    <>
      <Table.Advanced
        isLoading={false}
        headerData={headerData}
        data={SAMPLE_DATA_PROJECT}
        total={SAMPLE_DATA_PROJECT.length}
        mapValueRow={mapValueRow}
      />
    </>
  )
}

export default ProjectTable
