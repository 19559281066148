import { AppProgressType } from "slices/menuSlice"
import { useAppSelector } from "hooks/redux"
import { SubmitButton } from "components/Buttons"
import IcLoading from "assets/icons/ic_loading.svg"
import {
  StyledLoadingText,
  StyledLoading,
  StyledContainerLoading,
  StyledBoxError,
  StyledErrorTitle,
  StyleDivider,
  StyledErrorTextDesc,
  StyledErrorBoldTitle,
} from "./styles"

export default function AppProgress() {
  const { appProgress, showSidebar } = useAppSelector((state) => state.menu)

  function renderContent() {
    switch (appProgress) {
      case AppProgressType.LOADING:
        return (
          <StyledLoading>
            <img
              src={IcLoading}
              width={86}
              height={86}
              alt="Loading"
              className="loading-placeholder"
            />
            <StyledLoadingText variant="caption">로딩중...</StyledLoadingText>
          </StyledLoading>
        )
      case AppProgressType.COMMON_ERROR:
        return (
          <StyledBoxError>
            <StyledErrorTitle>
              <StyledErrorBoldTitle>문제가 발생</StyledErrorBoldTitle>했습니다.
              <br />
              관리자에게 문의하세요.
            </StyledErrorTitle>
            <StyleDivider />
            <StyledErrorTextDesc>
              이메일: boats@blockodyssey.io
            </StyledErrorTextDesc>
          </StyledBoxError>
        )
      case AppProgressType.SERVER_ERROR:
        return (
          <StyledBoxError>
            <StyledErrorTitle>
              <StyledErrorBoldTitle>서버에 문제가 발생</StyledErrorBoldTitle>
              했습니다.
              <br />
              관리자에게 문의하세요.
            </StyledErrorTitle>
            <StyleDivider />
            <StyledErrorTextDesc>
              이메일: boats@blockodyssey.io
            </StyledErrorTextDesc>
          </StyledBoxError>
        )
      case AppProgressType.SECTION_EXPIRED:
        return (
          <StyledBoxError>
            <StyledErrorTitle>
              <StyledErrorBoldTitle>
                페이지를 찾을 수 없습니다.
              </StyledErrorBoldTitle>
              <br />
              (404 Not Found)
            </StyledErrorTitle>
            <StyleDivider />
            <StyledErrorTextDesc>
              찾으려는 페이지의 주소가 잘못 입력되었거나,
              <br />
              주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.
              <br />
              입력하신 페이지의 주소가 정확한지 다시 한 번 확인해주세요.
            </StyledErrorTextDesc>
            <SubmitButton
              style={{
                marginTop: 24,
              }}
              text="요약 보기 가기"
              variant="contained"
            />
          </StyledBoxError>
        )
      default:
        return null
    }
  }

  return (
    <StyledContainerLoading
      className={`${
        appProgress !== AppProgressType.NONE ? "enable-page-loading" : ""
      } ${showSidebar ? "" : "enable-page-loading-hidden-sidebar"}`}
    >
      {renderContent()}
    </StyledContainerLoading>
  )
}
