import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Avatar, Typography } from "@mui/material"

import { Table } from "components"
import { ITableHead } from "components/Table/types"
import { SAMPLE_DATA_NFT, SAMPLE_DATA_PROJECT } from "dummy"
import { NFT_PENDING_TAB_INDEX } from "constants/data"
import { INFTTable } from "./types"

const NFTTable = ({ tabIndex }: INFTTable) => {
  const location = useLocation()

  const headerData1 = [
    {
      id: "id",
      label: "No",
      width: "5%",
      align: "center",
    },
    {
      id: "customerCompanyName",
      label: "브랜드명(고객사)",
      width: "10%",
      align: "left",
    },
    {
      id: "productImg",
      label: "NFT 이미지",
      width: "10%",
    },
    {
      id: "productName",
      label: "NFT이름 (상품명)",
      width: "10%",
    },
    {
      id: "detailView",
      label: "NFT 상세정보",
      width: "10%",
    },
    {
      id: "productId",
      label: "상품 id",
      width: "10%",
    },
    {
      id: "QRId",
      label: "QR id",
      width: "10%",
    },
    {
      id: "QRUrl",
      label: "QR URL",
      width: "10%",
    },
    {
      id: "QRImg",
      label: "QR 이미지",
      width: "10%",
    },
    {
      id: "QRCreatedDate",
      label: "프로젝트 검수 완료일시",
      width: "10%",
    },
  ]

  const headerData2 = [
    {
      id: "id",
      label: "No",
      width: "5%",
      align: "center",
    },
    {
      id: "customerCompanyName",
      label: "브랜드명(고객사)",
      width: "10%",
      align: "left",
    },
    {
      id: "productImg",
      label: "NFT 이미지",
      width: "10%",
    },
    {
      id: "productName",
      label: "NFT이름 (상품명)",
      width: "10%",
    },
    {
      id: "detailView",
      label: "NFT 상세정보",
      width: "10%",
    },
    {
      id: "productId",
      label: "상품 id",
      width: "10%",
    },
    {
      id: "QRId",
      label: "QR id",
      width: "10%",
    },
    {
      id: "tokenId",
      label: "토큰 id",
      width: "10%",
    },
    {
      id: "ownerWalletId",
      label: "소유자 지갑 주소",
      width: "10%",
    },
    {
      id: "NFTPublicDate",
      label: "NFT 발행일시",
      width: "10%",
    },
  ]

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const mapValueRow = (header: ITableHead, item: any) => {
    if (header.id === "detailView") {
      return (
        <Link
          to={
            tabIndex === NFT_PENDING_TAB_INDEX
              ? `${location.pathname}/details/pending/${item.id}`
              : `${location.pathname}/details/${item.id}`
          }
        >
          <Typography sx={{ textDecoration: "underline" }}>
            {item[header.id]}
          </Typography>
        </Link>
      )
    }

    if (header.id === "productImg" || header.id === "QRImg") {
      return (
        <Avatar
          variant="square"
          sx={{ height: 30, width: 30, margin: "0 auto" }}
          src={item[header.id]}
        />
      )
    }

    return item[header.id]
  }

  return (
    <>
      <Table
        isLoading={false}
        headerData={
          tabIndex === NFT_PENDING_TAB_INDEX ? headerData1 : headerData2
        }
        data={
          tabIndex === NFT_PENDING_TAB_INDEX
            ? SAMPLE_DATA_PROJECT
            : SAMPLE_DATA_NFT
        }
        total={
          tabIndex === NFT_PENDING_TAB_INDEX
            ? SAMPLE_DATA_PROJECT.length
            : SAMPLE_DATA_NFT.length
        }
        mapValueRow={mapValueRow}
      />
    </>
  )
}

export default NFTTable
