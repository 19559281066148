import { yupResolver } from "@hookform/resolvers/yup"
import _ from "lodash"
import * as yup from "yup"

// export const SCHEMA = {
//   Project: yup.object().shape(
//     {
//       projectType: yup.string().max(20).required("중복된 이름입니다."),
//       customerCompany: yup.string().required("중복된 이름입니다."),
//       // registerNum: yup.number().max(10),
//       projectName: yup.number(),
//     }
//   ),
// }

export const SCHEMA = yup.lazy((value: Record<string, string | number>) => {
  const shapes = {
    projectType: yup.number(),
    customerCompany: yup.string().required("중복된 이름입니다."),
    projectName: yup.string(),
  } as any
  const keys = Object.keys(_.omit(value, Object.keys(shapes)))
  keys.forEach((key: string) => {
    shapes[key] = yup.string().required("Please input")
  })
  return yup.object().shape(shapes)
})

export const DEFAULT_VALUE = {
  Project: {
    projectType: 0,
    customerCompany: "",
    projectName: "",
  },
}

export const FORM_OPTIONS = {
  resolver: yupResolver(SCHEMA),
  defaultValues: DEFAULT_VALUE.Project,
}
