/* eslint-disable import/prefer-default-export */
import { TextField } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledTextField = styled(TextField)(
  ({ theme: { border, palette } }) => ({
    width: 300,
    "& .MuiInput-root:before": {
      borderBottom: `2px solid ${border.opacity02}`,
    },
    "& input": {
      height: 42,
      fontWeight: 500,
      fontSize: 13,
      lineHeight: "65px",
      padding: "10.5px 18px",
      background: "rgba(79, 80, 104, 0.6)",
      borderRadius: 6,
      color: palette.common.white,
      boxSizing: "border-box",
    },
    "& fieldset": {
      border: "none",
    },
  })
)

export const StyledInput = styled(TextField)(({ theme: { palette } }) => ({
  "& input": {
    fontSize: "14px",
    color: palette.common.black,
  },
  "& .MuiInput-underline": {
    borderBottomColor: "#DCDCDC",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    paddingTop: 12.5,
    paddingBottom: 12.5,
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#DCDCDC",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#DCDCDC",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before": {
    borderBottomColor: "#DCDCDC",
    borderBottomWidth: 1,
  },
  "& .MuiInput-underline:focus:before": {
    borderBottomColor: "#DCDCDC",
    borderBottomWidth: 1,
  },
  "& input::placeholder": {
    color: "#7A7A7A",
    opacity: 1,
  },
}))
