import { Chip, styled } from "@mui/material"

export const StyledChip = styled(Chip)`
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 3px;
  height: unset;
  & .MuiChip-label {
    padding: 0;
  }
`
