import { createSlice } from "@reduxjs/toolkit"

export enum AppProgressType {
  NONE,
  LOADING,
  COMMON_ERROR,
  SERVER_ERROR,
  SECTION_EXPIRED,
}

type MenuType = {
  page: string
  menu: string | null
  menuTitle: string | null
  menuNum: number | null
  isExit: boolean
  showSidebar: boolean
  appProgress: AppProgressType
}

const initialState: MenuType = {
  page: "Dashboard",
  menu: "dashboard",
  menuTitle: "요약보기",
  menuNum: 0,
  isExit: false,
  showSidebar: true,
  appProgress: AppProgressType.NONE,
}

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenu: (state, { payload }) => {
      state.page = payload.page
      state.menu = payload.menu
      state.menuTitle = payload.menuTitle
      state.menuNum = payload.menuNum
      state.isExit = false
      state.showSidebar = false
    },
    setIsExit: (state, { payload }) => {
      state.isExit = payload
    },
    setShowMenu: (state) => {
      state.showSidebar = !state.showSidebar
    },
    setPageLoading: (state, { payload }: { payload: AppProgressType }) => {
      return {
        ...state,
        appProgress: payload,
      }
    },
  },
})

export const { setMenu, setIsExit, setShowMenu, setPageLoading } =
  menuSlice.actions

export const menuSelector = (state: { menu: MenuType }) => state.menu

export default menuSlice.reducer
