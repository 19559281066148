import { useState } from "react"

import { ThemeProvider } from "@mui/material/styles"
import { setSearchFilter } from "slices/searchSlice"
import { useAppDispatch } from "hooks/redux"

import { StyledForm } from "components/Search/styles"
import { SearchDatesType } from "components/Search/types"

import { DEFAULT_DATES } from "configs/search"
import theme from "styles/theme/search"

import SearchDatePicker from "../SearchDatePicker"

function FormSearch() {
  const dispatch = useAppDispatch()
  const [dates, setDates] = useState<SearchDatesType>(DEFAULT_DATES.Dashboard)

  const onSubmit = () => {
    dispatch(
      setSearchFilter([
        { type: "startDate", value: dates.startDate },
        { type: "endDate", value: dates.endDate },
      ])
    )
  }

  // 검색 기간 (기간 검색 부분의 Date Picker) 변경
  const onDateChange = (name: string, value: string | null) =>
    setDates((prev) => ({ ...prev, [name]: value, dateTerm: 0 }))

  return (
    <ThemeProvider theme={theme}>
      <StyledForm component="form">
        <SearchDatePicker
          term="dailySlash"
          dates={dates}
          onChange={onDateChange}
          onSubmit={() => onSubmit()}
        />
      </StyledForm>
    </ThemeProvider>
  )
}

export default FormSearch
