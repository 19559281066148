import { Controller, useFormContext } from "react-hook-form"

import ErrorMessage from "components/FormInputs/ErrorMessage"
import { StyledTextarea, StyledTextareaContainer } from "./styles"
import { ITextarea } from "./types"

function Textarea({
  showError = true,
  fullWidth = false,
  name,
  ...rest
}: ITextarea) {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <StyledTextareaContainer>
            <StyledTextarea
              id={`textarea-${name}`}
              name={name}
              value={value || ""}
              onChange={onChange}
              {...rest}
              fullWidth
            />
          </StyledTextareaContainer>
        )}
      />
      {showError && errors[name] && (
        <ErrorMessage text={String(errors[name]?.message)} />
      )}
    </>
  )
}

export default Textarea
