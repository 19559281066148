import { useState } from "react"
import { useMenu } from "hooks/common"
import { useMessage } from "hooks/modal"

import { Search } from "components"
import { SAMPLE_ROW_DATA } from "dummy"
import { PageHeader } from "layout"

import { MENU_CONFIG } from "configs/menu"
import QRTable from "./components/QRTable"
import RegisterModal from "./components/RegisterModal"
import { QRListConainer } from "./styles"

const QRList = () => {
  const menu = useMenu(MENU_CONFIG.qr)
  const handleMessage = useMessage()
  // const { isLoading, dataList } = useGetDashboardList({ menu })

  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false)

  // handle delete item
  const handleDeleteItem = () => {
    handleMessage({
      title: "고객사 삭제",
      message: "선택한 고객사를 목록에서 삭제하시겠습니까?",
      type: "confirm",
    })
  }

  const handleOpenRegisterProject = () => {
    setOpenRegisterModal(true)
  }

  const handleCloseRegisterProject = () => {
    setOpenRegisterModal(false)
  }

  const handleDownloadExcel = () => {}

  return (
    <>
      <PageHeader text="프로젝트 목록" />
      <QRListConainer>
        <Search
          total={SAMPLE_ROW_DATA.length}
          // label={{ search: "고객사 검색" }}
          dataList={{ searchCondition: [{ label: "2", value: 2 }] }}
          actions={{
            bottom: [
              { label: "선택 삭제", onClick: handleDeleteItem },
              {
                label: "신규 프로젝트 등록",
                onClick: handleOpenRegisterProject,
              },
              {
                label: "엑셀파일 다운로드",
                onClick: handleDownloadExcel,
              },
            ],
          }}
        />
        <QRTable />
      </QRListConainer>
      {openRegisterModal && (
        <RegisterModal
          isOpen={openRegisterModal}
          onClose={handleCloseRegisterProject}
        />
      )}
    </>
  )
}

export default QRList
