import { Box, Button, Grid, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import bgImage from "assets/images/login_background.svg"

export const StyledContainer = styled(Grid)(() => ({
  maxWidth: 960,
  height: 640,
}))

export const BlockLeft = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundImage: `url(${bgImage})`,
  backgroundPosition: "center",
  backgroundSize: "",
}))

export const BlockRight = styled(Grid)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

export const InnerLoginForm = styled(Box)(() => {
  return {
    width: 300,
    marginTop: 101,
  }
})

export const TitleForm = styled(Typography)(({ theme: { palette } }) => {
  return {
    fontWeight: 800,
    fontSize: 32,
    lineHeight: "38.19px",
    color: palette.common.black,
  }
})

export const DescForm = styled(Typography)(({ theme: { palette } }) => {
  return {
    fontWeight: 300,
    fontSize: 24,
    lineHeight: "28.64px",
    marginBottom: 32,
    color: palette.common.black,
  }
})

export const StyleControlContainer = styled(Box)(() => {
  return {
    width: "100%",
    marginBottom: 10,
    marginTop: 10,
  }
})

export const StyleMessageError = styled(Box)(() => {
  return {
    width: "100%",
    marginBottom: 20,
    marginTop: 10,
  }
})

export const StyledErrorContainer = styled(Box)(() => ({
  marginTop: 10,
}))

export const ButtonForm = styled(Button)(() => {
  return {
    fontWeight: 700,
    fontSize: 13,
    marginBottom: 16,
  }
})

export const TypographyPowered = styled(Typography)(() => {
  return {
    fontSize: 14,
    lineHeight: "16.71px",
    fontWeight: 500,
    marginBottom: 120,
    color: "#90A4AE",
  }
})
