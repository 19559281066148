/* eslint-disable import/prefer-default-export */
import {
  Paper,
  TableCell,
  TableSortLabel,
  Box,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import palette from "styles/theme/palette"

export const StyledTablePaper = styled(Paper)(({ theme: { border } }) => ({
  width: "100%",
  borderRadius: 0,
  overflow: "hidden",
  "& table": {
    minWidth: 750,
  },
}))

export const StyledTableSortLabel = styled(TableSortLabel)`
  color: ${palette.color.gray700};
  & .MuiTableSortLabel-icon {
    position: absolute;
    left: 100%;
  }
`

export const StyledTableCell = styled(TableCell)(({ theme: { palette } }) => ({
  padding: "18.5px 23px",
  fontWeight: 600,
  color: palette.text.label,
  fontSize: "12px",
  lineHeight: "14px",

  "& .MuiTableSortLabel-root": {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "14px",
    color: `${palette.text.label} !important`,
  },
}))

export const StyledContainerEmptyTable = styled(Box)(() => ({
  width: "100%",
  height: 478,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))
