import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

export const SCHEMA = {
  schema: yup.object().shape({
    company: yup.string().required(),
    manager: yup.string().required(),
    note: yup.string().required(),
  }),
}

export const DEFAULT_VALUE = {
  data: {
    company: "",
    manager: "",
    note: "",
  },
}

export const FORM_OPTIONS = {
  defaultValues: DEFAULT_VALUE.data,
  resolver: yupResolver(SCHEMA.schema),
}
