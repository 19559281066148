export const DATE_FORMAT = {
  daily: "yyyy-MM-dd",
  monthly: "yyyy-MM",
  hour: "yyyy-MM-dd HH:mm",
}

export const DATE_FORMAT_VIEW = {
  daily: ["day"],
  monthly: ["month", "year"],
  hour: ["month", "day", "hours", "minutes"],
}
