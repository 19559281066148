const palette = {
  primary: {
    light: "#3D393508",
    opacity02: "#3D393533",
    main: "#000000",
  },
  secondary: {
    main: "#607D8B",
  },
  error: {
    main: "#FF4A4A",
  },
  background: {
    default: "#f3f3f3",
    gainsboro: "#DDDDDD",
  },
  color: {
    bGray25: "#F1F5F7",
    gray25: "#F7F7F7",
    gray65: "#EAEAEA",
    gray400: "#9C9C9C",
    gray700: "#646464",
  },
  common: {
    white: "#ffffff",
    black: "#000000",
  },
  text: {
    primary: "#333333",
    secondary: "#455A64",
    label: "#646464",
    grey: "#7B7B7B",
    suvaGrey: "#929292",
  },
  border: {
    main: "#B9B9B9",
  },
  blue: {
    main: "#5872CF",
    contrastText: "#fff",
    dark: "#0054A7",
    cobalt: "#0054A7",
    slate: "#5872CF",
    navy: "#0857C3",
  },
  lavender: {
    main: "#EDF4FB",
  },
  red: {
    razzmatazz: "#F21255",
  },
}

export default palette
