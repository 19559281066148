import { useEffect } from "react"
import { TooltipItem } from "chart.js"
import _ from "lodash"
import moment from "moment"
import { Line } from "react-chartjs-2"
import { useCookies } from "react-cookie"
import {
  Box,
  Grid,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material"
import { AppProgressType, setPageLoading } from "slices/menuSlice"
import { useMenu } from "hooks/common"
import { useAppDispatch } from "hooks/redux"
import { Table } from "components"
import { ITableHead } from "components/Table/types"

import { SAMPLE_DATA_DASHBOARD, SAMPLE_ROW_DATA } from "dummy"

import IconInfo from "assets/icons/ic_info.svg"
import COOKIE_NAME from "configs/cookie/cookieNames"
import { MENU_CONFIG } from "configs/menu"
import palette from "styles/theme/palette"
import FormSearch from "./components/FormSearch"

import { IPaymentSummaryData, IPaymentSummaryItem } from "./hooks/types"
import useGetPaymentSummary from "./hooks/useGetPaymentSummary"
import {
  DashboardChartContainer,
  StyledChartContainer,
  StyledSubtitle,
  TooltipTableRow,
  TooltipTableCol2,
  TooltipTableCol1,
  TooltipTable,
  StyledTable,
  StyledTypography,
} from "./styles"

const headerData1 = [
  {
    id: "division",
    label: "구분",
    width: "130px",
  },
  {
    id: "sum",
    label: "합계",
    width: "130px",
  },
]

export default function List() {
  useMenu(MENU_CONFIG.dashboard)
  const dispatch = useAppDispatch()

  const mapValueRow = (header: ITableHead, item: any) => {
    if (header.id === "division") {
      return (
        <Grid container alignItems="center" columnGap={1.25}>
          <Box
            width={18}
            height={18}
            sx={{
              backgroundColor: item.divisionColor,
              marginLeft: "6px",
              borderRadius: "2px",
            }}
          />
          {item[header.id]}
        </Grid>
      )
    }
    return item[header.id]
  }

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: palette.common.white,
      padding: "20px",
      margin: "0 0 0 55px",
      boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.08)",
      borderRadius: "8px",
    },
  }))
  const { data, isLoading, error } = useGetPaymentSummary()

  useEffect(() => {
    if (isLoading) {
      dispatch(setPageLoading(AppProgressType.LOADING))
    } else {
      dispatch(setPageLoading(AppProgressType.NONE))

      if (_.isEmpty(data)) {
        dispatch(setPageLoading(AppProgressType.COMMON_ERROR))
      }

      if (error) {
        dispatch(setPageLoading(AppProgressType.SERVER_ERROR))
      }
    }
  }, [isLoading, data])

  const labels = (data as any)?.[0]?.data?.map?.(
    (item: IPaymentSummaryData) => item?.date
  )
  const dataAllModel = (data as any)?.[0]?.data?.map?.(
    (item: IPaymentSummaryData) => item?.value
  )
  const dataS9Model = (data as any)?.[1]?.data?.map?.(
    (item: IPaymentSummaryData) => item?.value
  )

  const dataTable = (data as any)
    ?.filter?.((_: IPaymentSummaryItem, pidx: number) => pidx <= 1)
    ?.map((paymentSummaryItem: IPaymentSummaryItem, index: number) => {
      const newItem = {} as any
      newItem.division = SAMPLE_DATA_DASHBOARD[index].division
      newItem.divisionColor = SAMPLE_DATA_DASHBOARD[index].divisionColor
      newItem.sum = `${paymentSummaryItem.total}회`
      paymentSummaryItem.data.forEach((item: IPaymentSummaryData, idx) => {
        newItem[idx] = `${item.value ? item.value : "-"}회`
      })
      return newItem
    })

  const headerData2 = labels?.map?.((label: any, idx: any) => ({
    id: idx,
    label,
    width: "130px",
  }))

  const customTooltip = {
    mode: "index" as const,
    intersect: false,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    titleFontColor: "#fff",
    bodyFontColor: "#fff",
    footerFontColor: "#fff",
    callbacks: {
      label(context: TooltipItem<"line">) {
        let label = context.dataset.label || ""
        const index = context.dataIndex
        if (label) {
          label += ": "
        }
        if (context.parsed.y !== null) {
          label += `${context.dataset.data[index]}`
        }
        return label
      },
      title(tooltipItem: TooltipItem<"line">[]) {
        return ` ${tooltipItem[0].label}`
      },
      labelColor(tooltipItem: TooltipItem<"line">) {
        const dataset = tooltipItem?.dataset
        if (dataset?.label === "전체") {
          return {
            borderColor: "#192124",
            backgroundColor: "#192124",
          }
        }
        if (dataset?.label === "S9 모델") {
          return {
            borderColor: "#008FFF",
            backgroundColor: "#008FFF",
          }
        }
        return {
          borderColor: "#000",
          backgroundColor: "#fff",
        }
      },
    },
  }

  return (
    <>
      <FormSearch />
      <DashboardChartContainer>
        <StyledTypography variant="h2" mb="20px">
          요약 그래프
        </StyledTypography>

        <StyledChartContainer>
          <StyledSubtitle variant="subtitle2">(단위 : 회)</StyledSubtitle>
          <Line
            data={{
              labels,
              datasets: [
                {
                  label: "전체",
                  data: dataAllModel,
                  borderColor: "#192124",
                  backgroundColor: "#FFFFFF",
                  borderWidth: 2,
                },
                {
                  label: "S9 모델",
                  data: dataS9Model,
                  borderColor: "#008FFF",
                  backgroundColor: "#008FFF",
                  pointBorderColor: "#000000",
                  pointBackgroundColor: "#FFFFFF",
                  pointBorderWidth: 2,
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  // title: {
                  //   display: true,
                  //   text: "(단위 : 회)",
                  //   align: "end",
                  //   font: {
                  //     size: 10,
                  //   },
                  //   color: "#000000",
                  // },
                  suggestedMax: 200,
                  suggestedMin: 0,
                  ticks: {
                    stepSize: 50,
                  },
                },
              },
              animation: {
                duration: 500,
              },
              elements: {
                line: {
                  tension: 0,
                  borderWidth: 2,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: customTooltip,
              },
            }}
          />
        </StyledChartContainer>
      </DashboardChartContainer>
      <StyledTable>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            marginBottom: "8px",
          }}
        >
          <HtmlTooltip
            placement="top-start"
            title={
              <>
                <Typography
                  color={palette.color.gray700}
                  fontSize="12px"
                  fontWeight="600"
                  lineHeight="14px"
                  paddingBottom="4px"
                >
                  사용자 행동에 따른 상황별 안내
                </Typography>
                <TooltipTable>
                  <TooltipTableRow
                    borderTop="1px solid #646464"
                    borderBottom="1px solid rgb(0,0,0,.3)"
                  >
                    <TooltipTableCol1 variant="h1" padding="12px 4px 8px">
                      <Box
                        bgcolor="#192124"
                        width="14px"
                        height="14px"
                        marginRight="8px"
                        borderRadius="2px"
                      />
                      전체
                    </TooltipTableCol1>
                    <TooltipTableCol2 padding="12px 12px 8px">
                      전체 모델 구매 시도 대수
                    </TooltipTableCol2>
                  </TooltipTableRow>
                  <TooltipTableRow borderBottom="1px solid #646464">
                    <TooltipTableCol1 padding="8px 4px 12px">
                      <Box
                        bgcolor="#008FFF"
                        width="14px"
                        height="14px"
                        marginRight="8px"
                        borderRadius="2px"
                      />
                      S9 모델
                    </TooltipTableCol1>
                    <TooltipTableCol2 padding="8px 12px 12px">
                      S9 모델 구매 시도 대수
                    </TooltipTableCol2>
                  </TooltipTableRow>
                </TooltipTable>
              </>
            }
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "12px",
                lineHeight: "14.32px",
                color: palette.text.label,
                fontWeight: 400,
              }}
            >
              <img
                src={IconInfo}
                width={14}
                height={14}
                alt="info"
                style={{
                  marginRight: 3,
                  verticalAlign: "middle",
                  cursor: "pointer",
                }}
              />
              사용자 행동에 따른 상황별 안내
            </Typography>
          </HtmlTooltip>
        </Grid>
        <Grid display="flex">
          <Grid>
            <Table.Styled
              isLoading={false}
              headerData={headerData1}
              data={dataTable}
              total={dataTable?.length}
              mapValueRow={mapValueRow}
              showPaginate={false}
            />
          </Grid>
          <Box
            sx={{
              overflow: "auto",
              "> div": {
                overflowY: "hidden",
              },
              borderRightWidth: 1,
              borderRightColor: "#EAEAEA",
              borderRightStyle: "solid",
            }}
          >
            <Table
              isLoading={false}
              headerData={headerData2}
              data={dataTable}
              total={dataTable?.length}
              mapValueRow={mapValueRow}
              showPaginate={false}
            />
          </Box>
        </Grid>
      </StyledTable>
    </>
  )
}
