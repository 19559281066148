import { FormControlLabel, Checkbox } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Label = styled(FormControlLabel)(({ theme: { palette } }) => ({
  fontSize: 13,
  lineHeight: "20px",
  letterSpacing: "-0.48px",
  marginTop: 0,
  marginBottom: 0,
  color: palette.text.label,

  "& span": {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 14,
    lineHeight: "16.71px",
    fontWeight: 600,
  },
}))

export const CheckBox = styled(Checkbox)(({ theme: { palette } }) => ({
  "&.Mui-checked": {
    color: palette.common.black,
  },
}))
