import AdvancedTable from "./AdvancedTable"
import NormalTable from "./NormalTable"
import StyledTable from "./StyledTable"
import { ITable } from "./types"

const Table = (props: ITable) => {
  return <NormalTable {...props} />
}

Table.Styled = StyledTable
Table.Normal = NormalTable
Table.Advanced = AdvancedTable

export default Table
