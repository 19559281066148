import { useState } from "react"

import { Box } from "@mui/material"
import { useMenu } from "hooks/common"
import { useMessage } from "hooks/modal"

import { Search } from "components"
import { SAMPLE_ROW_DATA } from "dummy"
import { PageHeader } from "layout"

import { MENU_CONFIG } from "configs/menu"
import { NFT_PENDING_TAB_INDEX, NFT_PUBLIC_TAB_INDEX } from "constants/data"
import NFTTable from "../components/Table"

const NFTList = () => {
  const menu = useMenu(MENU_CONFIG.nft)
  const handleMessage = useMessage()
  // const { isLoading, dataList } = useGetDashboardList({ menu })

  const [tabIndex, setTabIndex] = useState<number>(0)

  const handleTabOne = () => {
    setTabIndex(NFT_PENDING_TAB_INDEX)
  }

  const handleTabTwo = () => {
    setTabIndex(NFT_PUBLIC_TAB_INDEX)
  }

  return (
    <>
      <PageHeader text="프로젝트 목록" />
      <Search
        total={SAMPLE_ROW_DATA.length}
        // label={{ search: "고객사 검색" }}
        dataList={{ searchCondition: [{ label: "2", value: 2 }] }}
        tabList={[
          {
            label: "발행대기",
            onClick: handleTabOne,
            isChosen: tabIndex === 0,
          },
          {
            label: "발행완료",
            onClick: handleTabTwo,
            isChosen: tabIndex === 1,
          },
        ]}
      />
      <Box px="22px" bgcolor="white">
        <NFTTable tabIndex={tabIndex} />
      </Box>
    </>
  )
}

export default NFTList
