import { Box, Typography, Button } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  borderRadius: 16,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "hidden",
}))

export const StyledBoxText = styled(Box)(() => ({
  width: 328,
  height: 80,
  margin: "30px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

export const StyledTypographyDesc = styled(Typography)(() => ({
  textAlign: "center",
  verticalAlign: "middle",
  fontSize: 14,
  lineHeight: "16.71px",
  color: "#383838",
}))

export const StyledBoxFooter = styled(Box)(() => ({
  borderTopWidth: 1,
  borderTopColor: "#F1F1F1",
  borderTopStyle: "solid",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
}))

export const StyledButton = styled(Button)(({ theme: { palette } }) => ({
  paddingTop: 16,
  paddingBottom: 16,
  width: "100%",
  fontWeight: 600,
  fontSize: 14,
  color: palette.common.black,
  lineHeight: "16.71px",
}))
