import { useEffect, useRef } from "react"
import { useCookies } from "react-cookie"
import { Switch, Route, Redirect } from "react-router-dom"

import { setVisibleModalExpired } from "slices/loginSlice"
import { useInitPage } from "hooks/common"
import { useAppSelector, useAppDispatch } from "hooks/redux"
import Page404 from "pages/404"
import { ChangeInfo, ChangePassword } from "pages/Admin"
import Chart from "pages/Chart"
import CSV from "pages/CSV"
import Customer from "pages/Customer"
import CustomerDetail from "pages/Customer/CustomerDetail"
import CustomerEdit from "pages/Customer/CustomerEdit"
import {
  DashboardDetail,
  DashboardEdit,
  DashboardList,
  DashboardUpload,
} from "pages/Dashboard"
import Editor from "pages/Editor"
import { NFTDetail, NFTList } from "pages/NFT"
import { ProductDetail, ProductEdit, ProjectDetail, QRList } from "pages/QR"
import Search from "pages/Search"
import RouteWrapper from "routes/RouteWrapper"
import {
  DURATION_EXPIRED_ACCESS_TOKEN_COOKIE,
  ACCESS_TOKEN_INTERVAL,
} from "configs/cookie/accessToken"
import COOKIE_NAME from "configs/cookie/cookieNames"

function debounce(func: () => void, delay: number) {
  let timeId: any
  return function () {
    if (timeId) {
      clearTimeout(timeId)
    }
    timeId = setTimeout(() => {
      func()
    }, delay)
  }
}

export default function Router() {
  // const dispatch = useAppDispatch()
  // const visibleModalExpired = useAppSelector(
  //   (state) => state.login.visibleModalExpired
  // )
  // let intervalId = useRef<any>().current
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   COOKIE_NAME.COOKIE_ID,
  //   COOKIE_NAME.ACCESS_TOKEN,
  // ])

  useInitPage()

  // useEffect(() => {
  //   intervalId = setInterval(() => {
  //     if (!document?.cookie) {
  //       dispatch(setVisibleModalExpired(true))
  //     }
  //   }, ACCESS_TOKEN_INTERVAL)

  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId)
  //     }
  //   }
  // }, [])

  // useEffect(() => {
  //   if (visibleModalExpired && intervalId) {
  //     clearInterval(intervalId)
  //   }
  // }, [visibleModalExpired])

  // const updateExpireTime = debounce(() => {
  //   setCookie(COOKIE_NAME.ACCESS_TOKEN, "swing", {
  //     maxAge: DURATION_EXPIRED_ACCESS_TOKEN_COOKIE,
  //   })
  // }, 30000)

  // useEffect(() => {
  //   // Set initial Expire Time
  //   updateExpireTime()

  //   // set event listeners
  //   window.addEventListener("mousemove", updateExpireTime)

  //   // clean up
  //   return () => {
  //     window.removeEventListener("mousemove", updateExpireTime)
  //   }
  // }, [])

  return (
    <Switch>
      <RouteWrapper path="/" exact component={DashboardList} />

      <RouteWrapper path="/customer" exact component={Customer} />
      <RouteWrapper path="/customer/detail/:id" component={CustomerDetail} />
      <RouteWrapper path="/customer/create" component={CustomerEdit} />
      <RouteWrapper path="/customer/edit/:id" component={CustomerEdit} />

      <RouteWrapper path="/qr" exact component={QRList} />
      <RouteWrapper path="/qr/project/:id" exact component={ProjectDetail} />
      <RouteWrapper
        path="/qr/project/:projectId/product/:productId"
        exact
        component={ProductDetail}
      />
      <RouteWrapper
        path="/qr/project/:projectId/product/:productId/edit"
        exact
        component={ProductEdit}
      />

      <RouteWrapper path="/nft" exact component={NFTList} />
      <RouteWrapper path="/nft/details/:id" exact component={NFTDetail} />
      <RouteWrapper
        path="/nft/details/pending/:id"
        exact
        component={NFTDetail}
      />

      <RouteWrapper path="/upload" component={DashboardUpload} />
      <RouteWrapper path="/edit/:idx" component={DashboardEdit} />
      <RouteWrapper path="/detail/:idx" component={DashboardDetail} />

      <RouteWrapper path="/admin/info" exact component={ChangeInfo} />
      <RouteWrapper path="/admin/password" exact component={ChangePassword} />

      <RouteWrapper path="/search" exact component={Search} />
      <RouteWrapper path="/editor" exact component={Editor} />
      <RouteWrapper path="/csv" exact component={CSV} />
      <RouteWrapper path="/chart" exact component={Chart} />

      <Route path="/login" exact>
        <Redirect to="/" />
      </Route>
      <Route path="*" component={Page404} />
    </Switch>
  )
}
