import * as XLSX from "xlsx"

const exportToSpreadsheet = (
  data: any[],
  headerTitles: string[],
  orderTitle: string[],
  fileName: string
) => {
  const workSheet = XLSX.utils.json_to_sheet(data, {
    header: orderTitle,
  })
  XLSX.utils.sheet_add_aoa(workSheet, [headerTitles], { origin: "A1" })
  const workBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet")
  XLSX.writeFile(workBook, `${fileName}.xlsx`, { compression: true })
}

export default exportToSpreadsheet
