import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material"
import { styled } from "@mui/system"
import palette from "styles/theme/palette"

export const RegisterModalContainer = styled(Box)`
  width: 370px;
  max-width: 370px;
  height: 577px;
  background: ${palette.background.default};
  display: flex;
  flex-direction: column;

  & > * {
    padding: 26px 20px 0px !important;
  }
  * input {
    background: ${palette.common.white};
    border-radius: 4px;
  }
`

export const RegisterModalTitle = styled(DialogTitle)`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
`

export const RegisterModalContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
`

export const RegisterModalActions = styled(DialogActions)`
  margin-bottom: 26px;
  padding-right: 20px;
`

export const RegisterModalForm = styled(Grid)`
  max-width: 330px;
  /* & > div > div:nth-of-type(2) {
    max-width: 180px;
  } */
  & textarea {
    /* max-width: 180px; */
    height: 120px;
  }
`
