import { useEffect } from "react"
import currency from "currency.js"
import _ from "lodash"
import { Link, useHistory, useLocation } from "react-router-dom"
import { Grid, Typography } from "@mui/material"
import { AppProgressType, setPageLoading } from "slices/menuSlice"
import { useMenu } from "hooks/common"
import { useAppDispatch } from "hooks/redux"
import { PageHeader } from "layout"
import { MENU_CONFIG } from "configs/menu"
import palette from "styles/theme/palette"
import exportToSpreadsheet from "utils/excel"

import {
  isRefundState,
  mapPaymentStateToValue,
  mapVehicleToValue,
} from "utils/payment"
import {
  CustomerDetailButton,
  CustomerDetailContainer,
  CustomerDetailImg,
  CustomerDetailInfo,
  CustomerDetailTableInput,
  CustomerDetailTableRow,
  CustomerDetailTableTitle,
} from "./styles"
import { IPaymentDetail } from "../hooks/types"
import usePaymentDetail from "../hooks/usePaymentDetail"

const CustomerDetail = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  useMenu(MENU_CONFIG.customer)
  const location = useLocation()

  const { mutate, data, isLoading, error } = usePaymentDetail()

  useEffect(() => {
    const location = history?.location?.pathname || ""

    mutate({
      url: `${process.env.REACT_APP_BASE_URL}admin/payment/detail/${location
        .split("/")
        ?.pop()}`,
      data: {},
    })
  }, [history])

  useEffect(() => {
    if (isLoading) {
      dispatch(setPageLoading(AppProgressType.LOADING))
    } else {
      dispatch(setPageLoading(AppProgressType.NONE))

      if (_.isEmpty(data)) {
        dispatch(setPageLoading(AppProgressType.COMMON_ERROR))
      }

      if (error) {
        dispatch(setPageLoading(AppProgressType.SERVER_ERROR))
      }
    }
  }, [isLoading, data, error])

  function getFieldValue(key: keyof IPaymentDetail) {
    const value = _.get(data, key, "-")

    if (value === null) {
      return "-"
    }

    return value
  }

  const onExportExcel = () => {
    if (data) {
      exportToSpreadsheet(
        [
          {},
          {
            type: mapVehicleToValue(data.type),
            paymentState: mapPaymentStateToValue(data.paymentState),
            model: data.model,
            userId: data.userId,
            date: data.date,
            amount: data.amount,
            regionName: data.regionName,
            refundRequestDate: data.refundRequestDate,
            refundCompany: data.refundCompany,
            refundManager: data.refundManager,
            tossRefundDate: data.tossRefundDate,
            imageUrl: data.imageUrl,
          },
        ],
        [
          "기기 종류",
          "모델명",
          "구매자 회원번호",
          "구매일자",
          "결제 금액",
          "결제 여부",
          "환불 요청",
          "환불 요청",
          "환불 요청",
          "토스 환불 처리",
          "imageUrl",
        ],
        [
          "type",
          "model",
          "userId",
          "date",
          "amount",
          "regionName",
          "refundRequestDate",
          "refundCompany",
          "refundManager",
          "tossRefundDate",
          "imageUrl",
        ],
        `payment-${data.vehicleId}`
      )
    }
  }

  const isEnableRidingState = isRefundState(data?.paymentState)

  return (
    <>
      <PageHeader text="자세히 보기" path={`/customer${location.search}`} />
      <CustomerDetailContainer marginTop="-8px">
        <CustomerDetailInfo
          container
          flexWrap="nowrap"
          sx={{
            paddingBottom: isEnableRidingState ? 0 : "40px",
            paddingTop: "40px",
          }}
        >
          <Grid item marginRight="30px">
            <CustomerDetailImg>
              <img
                src={getFieldValue("imageUrl")}
                alt="BG vehicle info"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </CustomerDetailImg>
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <Typography
                variant="h5"
                fontWeight="600"
                color={palette.common.black}
                marginBottom="8px"
              >
                기기 정보
              </Typography>
              <CustomerDetailTableRow>
                <CustomerDetailTableTitle>모델번호</CustomerDetailTableTitle>
                <CustomerDetailTableInput>
                  {getFieldValue("vehicleId")}
                </CustomerDetailTableInput>
              </CustomerDetailTableRow>
              <CustomerDetailTableRow>
                <CustomerDetailTableTitle>기기 종류</CustomerDetailTableTitle>
                <CustomerDetailTableInput>
                  {mapVehicleToValue(getFieldValue("type"))}
                </CustomerDetailTableInput>
              </CustomerDetailTableRow>
              <CustomerDetailTableRow>
                <CustomerDetailTableTitle>모델명</CustomerDetailTableTitle>
                <CustomerDetailTableInput>
                  {getFieldValue("model")}
                </CustomerDetailTableInput>
              </CustomerDetailTableRow>
            </Grid>

            <Grid item marginTop="30px">
              <Typography
                variant="h5"
                fontWeight="600"
                color={palette.common.black}
                marginBottom="8px"
              >
                구매 정보
              </Typography>
              <CustomerDetailTableRow>
                <CustomerDetailTableTitle>
                  구매자 회원번호
                </CustomerDetailTableTitle>
                <CustomerDetailTableInput>
                  {getFieldValue("userId")}
                </CustomerDetailTableInput>
              </CustomerDetailTableRow>
              <CustomerDetailTableRow>
                <CustomerDetailTableTitle>구매일자</CustomerDetailTableTitle>
                <CustomerDetailTableInput>
                  {getFieldValue("date")}
                </CustomerDetailTableInput>
              </CustomerDetailTableRow>
              <CustomerDetailTableRow>
                <CustomerDetailTableTitle>결제 금액</CustomerDetailTableTitle>
                <CustomerDetailTableInput>
                  {currency(_.get(data, "amount", 0))
                    .format({
                      symbol: "",
                    })
                    .slice(0, -3)}
                  원
                </CustomerDetailTableInput>
              </CustomerDetailTableRow>
              <CustomerDetailTableRow>
                <CustomerDetailTableTitle>결제 여부</CustomerDetailTableTitle>
                <CustomerDetailTableInput>
                  {mapPaymentStateToValue(getFieldValue("paymentState"))}
                  {" - "}
                  {getFieldValue("method")}
                </CustomerDetailTableInput>
              </CustomerDetailTableRow>
              {/* <CustomerDetailTableRow>
                <CustomerDetailTableTitle>배치 지역</CustomerDetailTableTitle>
                <CustomerDetailTableInput>
                  {getFieldValue("regionName")}
                </CustomerDetailTableInput>
              </CustomerDetailTableRow> */}
            </Grid>
            {isEnableRidingState && (
              <Grid
                container
                style={{
                  marginTop: 18,
                }}
              >
                <Grid
                  item
                  marginRight="5px"
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <Link to={`/customer${location.search}`}>
                    <CustomerDetailButton variant="outlined">
                      목록
                    </CustomerDetailButton>
                  </Link>
                </Grid>
                <Grid item>
                  <CustomerDetailButton
                    variant="contained"
                    onClick={onExportExcel}
                  >
                    엑셀 다운로드
                  </CustomerDetailButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CustomerDetailInfo>
        {isEnableRidingState && (
          <CustomerDetailInfo
            container
            sx={{
              paddingTop: "32px",
            }}
          >
            <Grid item marginTop="30px" xs={12}>
              <Typography
                variant="h5"
                fontWeight="600"
                color={palette.common.black}
                marginBottom="8px"
              >
                환불 정보
              </Typography>
              <CustomerDetailTableRow>
                <CustomerDetailTableTitle>환불 요청</CustomerDetailTableTitle>
                <CustomerDetailTableInput>
                  {getFieldValue("refundRequestDate")} (환불 관리자 :{" "}
                  {getFieldValue("refundCompany")} -{" "}
                  {getFieldValue("refundManager")})
                </CustomerDetailTableInput>
              </CustomerDetailTableRow>
              <CustomerDetailTableRow>
                <CustomerDetailTableTitle>
                  토스 환불 처리
                </CustomerDetailTableTitle>
                <CustomerDetailTableInput>
                  {getFieldValue("tossRefundDate")}
                </CustomerDetailTableInput>
              </CustomerDetailTableRow>
            </Grid>
          </CustomerDetailInfo>
        )}
        {isEnableRidingState && (
          <CustomerDetailInfo
            container
            marginTop="15px"
            display="flex"
            justifyContent="flex-end"
            wrap="nowrap"
            sx={{
              paddingBottom: "40px",
            }}
          >
            <Grid item marginRight="5px">
              <Link to={`/customer${location.search}`}>
                <CustomerDetailButton variant="outlined">
                  목록
                </CustomerDetailButton>
              </Link>
            </Grid>
            <Grid item>
              <CustomerDetailButton variant="contained" onClick={onExportExcel}>
                엑셀 다운로드
              </CustomerDetailButton>
            </Grid>
          </CustomerDetailInfo>
        )}
      </CustomerDetailContainer>
    </>
  )
}

export default CustomerDetail
