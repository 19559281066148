import { DialogActions, DialogContent } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledDialogContent = styled(DialogContent)(() => ({
  minWidth: 320,
}))

export const StyledDialogActions = styled(DialogActions)(() => ({
  padding: 20,
}))
