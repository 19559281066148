import { Box, Grid, styled } from "@mui/material"
import palette from "styles/theme/palette"

export const NFTDetailContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > div:nth-of-type(1) {
    padding-bottom: 18px;
  }
  & > div:nth-of-type(2) {
    padding-top: 27px;
    padding-bottom: 17px;
  }
`

export const NFTDetailCard = styled(Box)`
  background: ${palette.common.white};
  padding: 15px 30px 30px 30px;
`

export const NFTDetailProperty = styled(Grid)``

export const NFTDetailActions = styled(Box)`
  margin-block: 30px;
`
