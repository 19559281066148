import { Avatar, Box, Button, Grid, Typography, styled } from "@mui/material"
import palette from "styles/theme/palette"

export const CustomerDetailContainer = styled(Box)`
  background-color: ${palette.common.white};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
`

export const CustomerDetailGrid = styled(Grid)`
  background-color: ${palette.common.white};
  padding: 46px 16px 25px;
  & > div {
    border: 0.8px solid #eaeaea;
    box-shadow: 0px 5px 10px 7.3347px rgba(34, 34, 34, 0.06);
    border-radius: 8.80164px;
  }
`
export const CustomerDetailInfo = styled(Grid)(() => ({
  paddingLeft: 40,
  paddingRight: 40,
}))

export const CustomerDetailButton = styled(Button)`
  padding: 12px 20px;
  width: 111px;
  height: 40px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
`

export const CustomerDetailInfoLeft = styled(Grid)`
  display: flex;
  flex-direction: column;
  // border-right: 0.5px solid ${palette.border.main};
`
export const CustomerDetailInfoRight = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-left: 37px;
  & > div:first-of-type {
    margin-bottom: 28px;
  }
`

export const CustomerDetailImg = styled(Avatar)`
  height: 490px;
  width: 440px;
  // margin-bottom: 12px;
  border-radius: 8px;
  background-color: ${palette.common.black};
  padding: 0 7px;
`
export const CustomerDetailTableRow = styled(Grid)`
  display: flex;
  flex-wrap: no-warp;
  background-color: ${palette.color.gray25};
  border-bottom: 1px solid ${palette.color.gray65};
  border-top: 1px solid ${palette.color.gray65};
`

export const CustomerDetailTableTitle = styled(Typography)`
  display: flex;
  align-items: center;
  min-width: 208px;
  height: 41px;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 16px;
  color: ${palette.color.gray700};
  background-color: ${palette.color.gray25};
`
export const CustomerDetailTableInput = styled(Typography)`
  display: flex;
  align-items: center;
  height: 41px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 16px;
  color: ${palette.common.black};
  background-color: ${palette.common.white};
`

export const CustomerNotes = styled(Grid)`
  margin-top: 20px;
  height: 360px;
  padding: 25px 31px 25px 21px;
  display: flex;
  flex-direction: column;
  & > h3 {
    margin-bottom: 13px;
  }
  & > textarea {
    resize: none;
    outline: 0;
    border: none;
    padding: 10px;
    height: 100%;
    flex: 1;
    background-color: ${palette.background.default};
    font-family: Pretendard, sans-serif;
    font-size: 11px;
  }
  & > p {
    margin-top: 3px;
    text-align: right;
    opacity: 0.4;
    font-size: 9px;
  }
  & > button {
    margin-top: 6px;
    align-self: baseline;
  }
`
