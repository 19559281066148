/* eslint-disable import/prefer-default-export */
import { ListItemButton } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledListItem = styled(ListItemButton)<{
  component?: React.ElementType
  to?: string
}>(({ theme: { palette } }) => ({
  width: "100%",
  // height: 34,
  margin: 0,
  paddingTop: 12,
  paddingBottom: 12,
  paddingLeft: 20,
  paddingRight: 20,
  backgroundColor: "transparent !important",
  borderRadius: 4,
  gap: 12,
  "&&:hover": {
    borderRadius: 4,
    "& p": {
      color: palette.common.white,
      opacity: 1,
    },
    "& svg": {
      color: palette.text.primary,
      "& path": {
        fill: palette.common.white,
      },
    },
  },
  "& .MuiListItemButton-root": {
    height: "fit-content",
  },
  "& .MuiListItemIcon-root": {
    minWidth: 24,
    maxWidth: 24,
  },
  "& svg": {
    minWidth: 20,
    color: palette.common.white,
    width: 20,
    height: 20,
    fill: palette.secondary.main,
    "& path": {
      fill: palette.secondary.main,
    },
  },
  "& .MuiListItemText-root": {
    margin: 0,
  },
  "& p": {
    textAlign: "left",
    color: palette.secondary.main,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "16.71px",
  },
}))
