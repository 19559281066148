import { Dashboard } from "@mui/icons-material"
import { ReactComponent as IcDashboard } from "assets/icons/ic_dashboard.svg"
import { ReactComponent as IcScooter } from "assets/icons/ic_scooter.svg"

export const MENU = {
  private: [
    {
      setCaption: true,
      caption: "",
      menu: ["dashboard"],
      siblings: [
        {
          num: 0,
          menu: "dashboard",
          menuTitle: "요약보기",
          icon: <IcDashboard color="white" />,
          path: "/",
        },
        {
          num: 1,
          menu: "customer",
          menuTitle: "판매 현황",
          icon: <IcScooter color="white" />,
          path: "/customer",
        },
      ],
    },
    {
      setCaption: true,
      caption: "",
      menu: ["components", "common"],
    },
  ],
  public: [
    {
      setCaption: true,
      caption: "홈",
      menu: ["dashboard"],
      siblings: [
        {
          num: 0,
          menu: "dashboard",
          menuTitle: "Dashboard",
          icon: <Dashboard />,
          path: "/",
        },
      ],
    },
  ],
}

export const MENU_CONFIG = {
  dashboard: {
    page: "Dashboard",
    menu: "dashboard",
    menuTitle: "요약보기",
    menuNum: 0,
  },
  search: {
    page: "Dashboard",
    menu: "search",
    menuTitle: "Search",
    menuNum: 1,
  },
  editor: {
    page: "Editor Component Demo",
    menu: "components",
    menuTitle: "Editor",
    menuNum: 2,
  },
  csv: {
    page: "CSV Component Demo",
    menu: "csv",
    menuTitle: "CSV",
    menuNum: 3,
  },
  chart: {
    page: "Chart",
    menu: "chart",
    menuTitle: "요약보기",
    menuNum: 4,
  },
  changeInfo: {
    page: "ChangeInfo",
    menu: null,
    menuTitle: "정보 수정",
    menuNum: null,
  },
  changePassword: {
    page: "ChangePassword",
    menu: null,
    menuTitle: "비밀번호 수정",
    menuNum: null,
  },
  customer: {
    page: "Customer",
    menu: "customer",
    menuTitle: "판매 현황",
    menuNum: 1,
  },
  qr: {
    page: "QRManagement",
    menu: "QR",
    menuTitle: "QR코드 관리",
    menuNum: 2,
  },
  project: {
    page: "ProjectDetail",
    menu: "Project",
    menuTitle: "QR코드 관리",
    menuNum: 2,
  },
  nft: {
    page: "NFTManagement",
    menu: "NFT",
    menuTitle: "NFT 관리",
    menuNum: 3,
  },
}
