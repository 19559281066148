export default {
  Dashboard: {
    upload: true,
    uploadText: "신규 등록",
    uploadPath: "/upload",
  },
  Chart: {
    upload: false,
    uploadText: "",
    uploadPath: "",
  },
  Customer: {
    upload: false,
    uploadText: "",
    uploadPath: "",
  },
  QRManagement: {
    upload: false,
    uploadText: "",
    uploadPath: "",
  },
  ProjectDetail: {
    upload: false,
    uploadText: "",
    uploadPath: "",
  },
  NFTManagement: {
    upload: false,
    uploadText: "",
    uploadPath: "",
  },
}
