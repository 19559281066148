import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios"
import _ from "lodash"

import {
  setLogOut,
  setTimeCount,
  setVisibleModalExpired,
} from "slices/loginSlice"

import { store } from "app/store"
import { SESSION_TIME_EXPIRED_MILISECONDS } from "constants/data"

declare module "axios" {
  export interface AxiosRequestConfig {
    retry?: boolean
    fileFlag?: boolean
  }
}

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000,
})

instance.defaults.headers.common.Accept = "application/json"
instance.defaults.headers.common["Content-Type"] =
  "application/json; charset=UTF-8"
instance.defaults.withCredentials = true // 토큰방식이 아닌 세션을 사용할 경우

// Handle set time count down to store
const handleSetTimeCount = () => {
  const { dispatch } = store
  const time = new Date().getTime()
  dispatch(setTimeCount(time))
  localStorage.setItem("timeCountDown", String(time))
}
// debounce execute function prevent call multiple times
const handleStoreTimeCountDown = _.debounce(handleSetTimeCount, 500)

// axios 헤더 설정
instance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (config.headers) {
      if (config.fileFlag === true) {
        config.headers["Content-Type"] = "multipart/form-data"
        delete config.fileFlag
      }

      const accessToken = localStorage.getItem("accessToken")
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
    }

    return config
  }
)

// Reissuance and re-request upon token expiration
let isTokenRefreshing = false
let refreshSubscribers: ((accessToken: string) => void)[] = []

const onTokenRefreshed = (accessToken: string) =>
  refreshSubscribers.map((callback) => callback(accessToken))
const addRefreshSubscriber = (callback: (accessToken: string) => void) =>
  refreshSubscribers.push(callback)

instance.interceptors.response.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (res: AxiosResponse<any>) => {
    // handle success response
    handleStoreTimeCountDown()
    return res
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (err: AxiosError<any>) => {
    const { dispatch } = store

    const errorObject = {
      title: "일시적인 오류",
      statusCode: err.response?.status || 500,
      message:
        err.response?.data?.message ||
        "다시 시도해 주세요. \n동일한 오류가 지속된다면 관리자에게 문의해 주세요.",
    }

    if (err.message === "Network Error") {
      errorObject.title = "네트워크 오류"
    }

    // handle access token expired
    const originalRequest = err.config
    if (err.response?.status === 401 && !originalRequest.retry) {
      const timeCountDown = Number(localStorage.getItem("timeCountDown")) || 0
      if (
        new Date().getTime() - timeCountDown >=
        SESSION_TIME_EXPIRED_MILISECONDS
      ) {
        dispatch(setVisibleModalExpired(true))
        return false
      }

      // reveal a new access token by using refresh token
      if (isTokenRefreshing === false) {
        isTokenRefreshing = true

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}admin/refresh`,
            {},
            { withCredentials: true }
          )
          .then((res) => res.data)
          .then(({ accessToken }) => {
            // re store access token
            localStorage.setItem("accessToken", accessToken)

            // refreshSubscribers 에 들어있는 있는 콜백들을 새로운 토큰으로 순차적 실행
            onTokenRefreshed(accessToken)
          })
          .catch(async () => {
            dispatch(setVisibleModalExpired(true))
            dispatch(setLogOut())
          })
          .finally(() => {
            refreshSubscribers = []
            isTokenRefreshing = false
          })
      }

      // refreshSubscribers 에 토큰을 인자로 받는 콜백함수 추가
      const retryOriginalRequest = new Promise((resolve) => {
        addRefreshSubscriber((accessToken: string) => {
          originalRequest.headers!.Authorization = `Bearer ${accessToken}`
          resolve(
            axios(originalRequest)
              .then((res) => res)
              .catch((error) => {
                // const errorObject = {
                //   title: "일시적인 오류",
                //   statusCode: error.response?.status || 500,
                //   message:
                //     error.response?.data?.message ||
                //     "다시 시도해주세요. \n동일한 오류가 지속된다면 관리자에게 문의해주세요.",
                // }
                // if (error.message === "Network Error") {
                //   errorObject.title = "네트워크 오류"
                // }
                // return Promise.reject(errorObject)
              })
          )
        })
      })

      originalRequest.retry = true
      return retryOriginalRequest
    }

    return Promise.reject(errorObject)
  }
)

export default instance
