import { useEffect } from "react"

import { useCookies } from "react-cookie"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"

import { MessageModal } from "components/Modals"
import LoginForm from "pages/Login/components/Form"
import useLogin from "pages/Login/hooks/useLogin"
import { StyledContainer } from "pages/Login/styles"
import { IFormValues } from "pages/Login/types"

import COOKIE_NAME from "configs/cookie/cookieNames"
import { FORM_OPTIONS } from "configs/form/login"

export default function Login() {
  const methods = useForm<IFormValues>(FORM_OPTIONS)
  const [cookies, setCookie, removeCookie] = useCookies([
    COOKIE_NAME.COOKIE_ID,
    COOKIE_NAME.ACCESS_TOKEN,
  ])

  useEffect(() => {
    if (cookies.cookieID) {
      methods.setValue("email", cookies.cookieID)
      methods.setValue("cookie", true)
    }
  }, [methods, cookies.cookieID])

  const { mutate, isLoading } = useLogin({
    formData: methods.getValues(),
    setCookie,
    removeCookie,
  })

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    mutate(
      {
        url: `${process.env.REACT_APP_BASE_URL}admin/login`,
        data: { email: data.email, password: data.password },
      },
      {
        onError: (error: any) => {
          if (error.statusCode === 400) {
            switch (error.message) {
              case "로그인 시도 5회 오류로 계정이 비활성화되었습니다.\n 관리자에게 문의하세요.\n\n 관리자 문의 이메일 : manager@blockodyssey.io":
                methods.setError("server", {
                  type: "value",
                  message: `남은 로그인 시도 횟수는 4회입니다.
                  5회 연속 실패 시 계정이 비활성화됩니다.`,
                })
                break
              default:
                methods.setError("try", {
                  type: "value",
                  message: error.message,
                })
                break
            }
          }
        },
      }
    )

    // if (data.email === "admin@blockodyssey.io" && data.password === "admin") {
    //   dispatch(setLogIn({}))
    //   setCookie("cookieID", data.email, { maxAge: COOKIE_TIME_EXPIRED })
    //   localStorage.setItem("isLogin", "0")
    // } else {
    //   methods.setError("server", {
    //     type: "value",
    //     message: `남은 로그인 시도 횟수는 4회입니다.
    // 5회 연속 실패 시 계정이 비활성화됩니다.`,
    //   })
    // }
  }

  const onError = (error: any) => {
    if (error?.email?.type === "email" && !error?.password) {
      methods.setError("try", {
        type: "value",
        message: error?.email?.message,
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <StyledContainer>
        <LoginForm
          isLoading={isLoading}
          onSubmit={onSubmit}
          onError={onError}
        />
        <MessageModal />
      </StyledContainer>
    </FormProvider>
  )
}
