export const NFT_PENDING_TAB_INDEX = 0
export const NFT_PUBLIC_TAB_INDEX = 1

export const DATA_SEARCH_TYPE_OPTIONS = [
  { label: "전체", value: "ALL" },
  { label: "주문번호", value: "ORDERID" },
  { label: "모델명", value: "MODEL" },
  { label: "모델번호", value: "VEHICLEID" },
  { label: "회원번호", value: "USERID" },
]

export const DATA_PAYMENT_STATE_OPTIONS = [
  { label: "전체", value: "ALL" },
  { label: "성공", value: "SUCCESS" },
  { label: "실패", value: "FAIL" },
  { label: "환불", value: "REFUND" },
  { label: "대기중", value: "WAITING" },
]

export const DATA_PAYMENT_COMPANY_OPTIONS = [
  { label: "블록오디세이", value: "블록오디세이" },
  { label: "스윙", value: "스윙" },
]

export const DATA_PAGESIZE_TABLE = [
  {
    label: "10개씩 보기",
    value: 10,
  },
  {
    label: "20개씩 보기",
    value: 20,
  },
  {
    label: "50개씩 보기",
    value: 50,
  },
  {
    label: "100개씩 보기",
    value: 100,
  },
]

export const SESSION_TIME_EXPIRED_MILISECONDS = 5 * 60 * 1000
