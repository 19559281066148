import { useFormContext } from "react-hook-form"

import { ErrorMessage, SubmitButton } from "components"
import {
  DescForm,
  StyledContainer,
  BlockLeft,
  BlockRight,
  InnerLoginForm,
  TitleForm,
  StyleControlContainer,
  TypographyPowered,
  StyleMessageError,
} from "pages/Login/components/Form/styles"
import { IForm } from "pages/Login/components/Form/types"
import { IFormValues } from "pages/Login/types"
import logo from "assets/icons/ic_login_swing.svg"
import Checkbox from "../Checkbox"
import Input from "../Input"

function MessageErrorWhenLogin5TimesFailed() {
  return (
    <StyleControlContainer>
      <ErrorMessage
        text={`로그인 시도 5회 오류로 계정이 비활성화되었습니다.
관리자에게 문의하세요.

관리자 문의 이메일 : manager@blockodyssey.io`}
      />
    </StyleControlContainer>
  )
}

function Form({ isLoading, onSubmit, onError }: IForm) {
  const {
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useFormContext<IFormValues>()

  return (
    <StyledContainer container>
      <BlockLeft item xs={6}>
        <img
          src={logo}
          width={190}
          height={74}
          alt="logo admin"
          style={{
            marginTop: 249,
            marginBottom: "auto",
          }}
        />

        <TypographyPowered>Powered by Block Odyssey</TypographyPowered>
      </BlockLeft>
      <BlockRight item xs={6}>
        <InnerLoginForm
          component="form"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <TitleForm variant="h1">SWING X BOATS</TitleForm>
          <DescForm>통합 로그인</DescForm>

          <StyleControlContainer>
            <Input name="email" fullWidth placeholder="아이디" clearable />
          </StyleControlContainer>
          <StyleControlContainer>
            <Input
              name="password"
              inputType="password"
              fullWidth
              placeholder="비밀번호"
            />
          </StyleControlContainer>

          {isSubmitted && errors?.try && (
            <StyleMessageError>
              <ErrorMessage text={errors.try.message} />
            </StyleMessageError>
          )}

          <StyleControlContainer
            style={{
              marginTop: 20,
              marginBottom: 56,
            }}
          >
            <Checkbox />
          </StyleControlContainer>

          <SubmitButton
            variant="contained"
            disabled={isLoading}
            type="submit"
            text="로그인"
            style={{
              width: "100%",
              fontSize: 14,
              fontWeight: 500,
              height: 56,
              borderRadius: 8,
            }}
          />

          {isSubmitted && errors?.server && (
            <MessageErrorWhenLogin5TimesFailed />
          )}
        </InnerLoginForm>
      </BlockRight>
    </StyledContainer>
  )
}

export default Form
