import { Select, MenuItem } from "@mui/material"
import { styled } from "@mui/material/styles"
import palette from "styles/theme/palette"

export const StyledSelect = styled(Select)<{
  isChangeColor: boolean
  isChoosePlaceholder?: boolean
}>(({ theme: { palette } }) => ({
  minWidth: 190,
  maxWidth: 200,
  backgroundColor: palette.common.white,
  color: palette.common.black,
  "& .MuiSelect-icon": {
    color: palette.common.black,
  },
  "& .MuiMenuItem-root": {
    paddingTop: "10px !important",
  },
}))

export const StyledMenuItem = styled(MenuItem)(() => ({
  justifyContent: "flex-start",
  padding: 12,
  "&:last-child": {
    "& .MuiTouchRipple-root": {
      borderBottomStyle: "none",
    },
  },

  "&.Mui-selected": {
    backgroundColor: palette.color.bGray25,
  },

  "& .MuiTouchRipple-root": {
    marginRight: 12,
    marginLeft: 12,
  },
}))
