import { useEffect, useMemo, useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { v4 as uuidv4 } from "uuid"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { Button, ButtonBase, Dialog, FormLabel, Grid } from "@mui/material"
import { RadioButton, Select, Input, Textarea } from "components"
import { FILE_TYPE, getFiles } from "utils"
import { FORM_OPTIONS } from "configs/form/project"
import {
  RegisterModalActions,
  RegisterModalContainer,
  RegisterModalContent,
  RegisterModalForm,
  RegisterModalTitle,
} from "./styles"
import { IRegisterModal, IRegisterModalFormItem } from "./types"

export default function RegisterModal({ isOpen, onClose }: IRegisterModal) {
  const methods = useForm(FORM_OPTIONS)
  const projectType = useWatch({
    control: methods.control,
    name: "projectType",
  })
  const [properties, setProperties] = useState<Array<Record<string, string>>>(
    []
  )

  const handleAddMoreProperty = () => {
    const newProperties = [
      ...properties,
      { label: "", value: "", id: uuidv4() },
    ]
    setProperties(newProperties)
  }

  const handleRemoveProperty = (pid: string) => {
    const newProperties = properties.filter(({ id }) => id !== pid)
    setProperties(newProperties)
  }

  const handleCancel = () => {
    onClose()
  }

  const handleRegister = () => {
    onClose()
  }

  const formInputs: IRegisterModalFormItem[] = [
    {
      label: "프로젝트 유형",
      name: "projectType",
      type: "radio",
      selectList: [
        {
          label: "QR 대량 발행",
          value: 0,
        },
        {
          label: "QR 단건 발행",
          value: 1,
        },
      ],
      isRequired: true,
    },
    {
      label: "고객사",
      name: "customerCompany",
      type: "select",
      selectList: [
        {
          label: "tootoo denim",
          value: 0,
        },
        {
          label: "IUOU",
          value: 1,
        },
      ],
      isRequired: true,
    },
    {
      label: "프로젝트명",
      name: "projectName",
      type: "text",
      isRequired: true,
    },
    {
      mode: 0,
      label: "엑셀 업로드",
      name: "excelAttach",
      type: "file",
      isRequired: true,
    },
    {
      mode: 0,
      label: "zip 파일 업로드",
      name: "zipAttach",
      type: "file",
      isRequired: false,
    },
    {
      mode: 1,
      label: "NFT명",
      name: "NFTName",
      type: "text",
      isRequired: true,
    },
    {
      mode: 1,
      label: "NFT 이미지",
      name: "NFTImage",
      type: "file",
      isRequired: true,
    },
    {
      mode: 1,
      label: "상품설명(NFT 설명)",
      name: "NFTDesc",
      type: "textarea",
      isRequired: true,
    },
  ]

  const handleFiles = async () => {
    const files = await getFiles(FILE_TYPE.EXCEL, false)
    // console.log(files)
  }

  const filtedForm = useMemo(
    () =>
      formInputs.filter((input, idx) => {
        return idx < 3 || String(projectType) === String(input.mode)
      }),
    [projectType]
  )

  const renderForm = (item: IRegisterModalFormItem) => {
    switch (item.type) {
      case "radio":
        return (
          item.selectList && (
            <RadioButton
              name={item.name}
              options={item.selectList}
              defaultValue={0}
            />
          )
        )
      case "select":
        return (
          item.selectList && (
            <Select name={item.name} options={item.selectList} />
          )
        )

      case "file":
        return (
          <Grid container columnSpacing={2} alignItems="flex-start">
            <Grid item xs={8} container direction="column">
              <Input name={item.name} value="test.png" disabled />
            </Grid>
            <Grid item xs={4} alignSelf="strech">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => handleFiles()}
              >
                첨부
              </Button>
            </Grid>
          </Grid>
        )
      case "textarea":
        return <Textarea name={item.name} fullWidth />
      default:
        return <Input name={item.name} fullWidth />
    }
  }

  useEffect(() => {
    methods.clearErrors()
  }, [projectType])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <FormProvider {...methods}>
        <RegisterModalContainer
          component="form"
          onSubmit={methods.handleSubmit(handleRegister)}
        >
          <RegisterModalTitle>신규 프로젝트 등록</RegisterModalTitle>
          <RegisterModalContent>
            <RegisterModalForm container direction="column" rowGap={0.8}>
              {filtedForm.map((input: IRegisterModalFormItem) => (
                <Grid key={input.name} item container alignItems="center">
                  <Grid item xs={4}>
                    <FormLabel required={input.isRequired}>
                      {input.label}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={8}>
                    {renderForm(input)}
                  </Grid>
                </Grid>
              ))}
              {projectType === "1" &&
                properties.map((property, idx) => (
                  <>
                    <Grid
                      key={`property_${idx}`}
                      item
                      container
                      alignItems="center"
                    >
                      <Grid item xs={4} pr="6px">
                        <Input name={`property_name_${idx}`} fullWidth />
                      </Grid>
                      <Grid item xs={7} pr="6px">
                        <Input name={`property_value_${idx}`} fullWidth />
                      </Grid>
                      <Grid item xs={1}>
                        <ButtonBase
                          disableRipple
                          onClick={() => handleRemoveProperty(property.id)}
                        >
                          <HighlightOffIcon />
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  </>
                ))}
              {projectType === "1" && (
                <Grid container>
                  <Grid item xs={4} />
                  <Grid item xs={8}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleAddMoreProperty}
                      fullWidth
                    >
                      속성 추가{" "}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </RegisterModalForm>
          </RegisterModalContent>
          <RegisterModalActions>
            <Button variant="outlined" onClick={handleCancel}>
              취소
            </Button>
            <Button type="submit" variant="outlined">
              등록
            </Button>
          </RegisterModalActions>
        </RegisterModalContainer>
      </FormProvider>
    </Dialog>
  )
}
