import React from "react"
import { Link } from "react-router-dom"
import { Typography } from "@mui/material"

import { Table } from "components"
import { ITableHead } from "components/Table/types"
import { SAMPLE_DATA_QR } from "dummy"

import { numberWithCommas } from "utils/common"
import { StyledChip } from "./styles"

const QRTable = () => {
  const headerData = [
    {
      id: "id",
      label: "No",
      width: "5%",
      align: "center",
    },
    {
      id: "customerCompanyName",
      label: "고객사",
      width: "15%",
      align: "left",
    },
    {
      id: "projectName",
      label: "프로젝트명",
      width: "10%",
    },
    {
      id: "projectId",
      label: "프로젝트 id",
      width: "10%",
    },
    {
      id: "QRIssueTotal",
      label: "발행 QR 총계",
      width: "10%",
    },
    {
      id: "projectCreatedDate",
      label: "프로젝트 생성일시",
      width: "10%",
    },
    {
      id: "mappingStatus",
      label: "매핑 상태",
      width: "10%",
    },
    {
      id: "mappingDate",
      label: "매핑 일시",
      width: "10%",
    },
    {
      id: "inspectStatus",
      label: "검수 상태",
      width: "10%",
    },
    {
      id: "inspectDate",
      label: "검수 일시",
      width: "10%",
    },
  ]

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const mapValueRow = (header: ITableHead, item: any) => {
    if (header.id === "projectId") {
      return (
        <Link to={`/qr/project/${2}`}>
          <Typography sx={{ textDecoration: "underline" }}>
            {item[header.id]}
          </Typography>
        </Link>
      )
    }
    if (header.id === "inspectStatus") {
      return (
        <StyledChip
          clickable={false}
          color="primary"
          variant="outlined"
          onClick={handleClick}
          label={item[header.id]}
        />
      )
    }
    if (header.id === "QRIssueTotal") {
      return `${numberWithCommas(item[header.id])}개`
    }

    return item[header.id]
  }

  return (
    <>
      <Table.Advanced
        isLoading={false}
        headerData={headerData}
        data={SAMPLE_DATA_QR}
        total={SAMPLE_DATA_QR.length}
        mapValueRow={mapValueRow}
      />
    </>
  )
}

export default QRTable
