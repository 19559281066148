/* eslint-disable import/prefer-default-export */
import { Grid, Paper } from "@mui/material"
import { styled } from "@mui/material/styles"
import palette from "styles/theme/palette"

export const StyledTablePaper = styled(Paper)(({ theme: { border } }) => ({
  width: "100%",
  borderRadius: 0,
  overflow: "hidden",
  "& table": {
    minWidth: 750,
  },
  boxShadow: "none",
}))

export const DataRetrieved = styled(Grid)`
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  color: ${palette.common.black};
  span {
    font-weight: 600;
  }
`
