/* eslint-disable import/prefer-default-export */
import { Grid, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Box } from "@mui/system"
import palette from "styles/theme/palette"

export const DashboardChartContainer = styled(Box)(
  ({ theme: { palette } }) => ({
    backgroundColor: palette.common.white,
    padding: 20,
    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.08)",
    marginBottom: 14,
  })
)

export const SummaryBox = styled(Box)`
  display: flex;
  gap: 45px;
`

export const TooltipTable = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const TooltipTableRow = styled(Grid)`
  display: flex;
  align-items: center;
`

export const TooltipTableCol1 = styled(Typography)`
  display: flex;
  min-width: 80px;
  color: ${palette.color.gray700};
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
`
export const TooltipTableCol2 = styled(Typography)`
  width: 117px;
  background: ${palette.color.gray25};
  color: ${palette.common.black};
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
`

export const SummaryItem = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const StyledChartContainer = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: 520,
}))

export const StyledSubtitle = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 12,
  lineHeight: "14px",
  marginLeft: 20,
  color: "#9C9C9C",
}))

export const StyledTypography = styled(Typography)(
  ({ theme: { palette } }) => ({
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "19.09px",
    marginBottom: 14,
    color: palette.common.black,
  })
)

export const StyledTable = styled(Box)(({ theme: { palette } }) => ({
  padding: 20,
  backgroundColor: palette.common.white,
  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.08)",
}))
