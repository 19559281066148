const customTheme = {
  border: {
    main: "#3D393534",
    light: "#00000033",
    opacity01: "#3d39351a",
    opacity02: "#3d393533",
    dark: "#3D3935",
    silver: "#B9B9B9",
  },
  neutral: {
    main: "#4B4B4B",
    light: "#fbfbfb",
    lightOpacity07: "#f3f3f3b3",
    active: "#e7e7e7",
  },
  shadow: {
    main: "#E8E8E888",
  },
  disabled: {
    main: "#0000001f",
  },
  blue: {
    cobalt: "#0054A7",
    slate: "#5872CF",
    navy: "#0857C3",
  },
  red: {
    razzmatazz: "#F21255",
  },
}

export default customTheme
