export const helperMonthChange = (value: any) => {
  const date = new Date(value)

  const month = date.getMonth()
  const year = date.getFullYear()
  const firstDayOfMonth = new Date(year, month, 1).getDay()
  const lastDateOfMonth = new Date(year, month + 1, 0).getDate()
  const element = document.getElementsByClassName("MuiDateCalendar-root")[0]

  if (
    month !== 2 &&
    (firstDayOfMonth === 6 || (firstDayOfMonth === 5 && lastDateOfMonth === 31))
  ) {
    element.classList.add("MuiDateCalendar-root-long")
  } else {
    element.classList.remove("MuiDateCalendar-root-long")
  }
}
