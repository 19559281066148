import { useMenu } from "hooks/common"
import { useMessage } from "hooks/modal"
import { Search } from "components"
import { SAMPLE_ROW_DATA } from "dummy"
import { PageHeader } from "layout"
import { MENU_CONFIG } from "configs/menu"

import ProjectTable from "./components/ProjectTable"
import { ProjectDetailContainer } from "./styles"

const ProjectDetail = () => {
  const menu = useMenu(MENU_CONFIG.project)
  const handleMessage = useMessage()

  // handle delete item
  const handleDeleteItem = () => {
    handleMessage({
      title: "고객사 삭제",
      message: "선택한 고객사를 목록에서 삭제하시겠습니까?",
      type: "confirm",
    })
  }

  const handleMarkMappingDone = () => {}

  const handleDownloadExcel = () => {
    handleMessage({
      title: " ",
      message: "선택된 항목을 다운로드 받으시겠습니까?",
      type: "confirm",
    })
  }

  return (
    <>
      <PageHeader
        text="프로젝트 상세"
        path="/qr"
        desc="[프로젝트 ID : T000001_01]  차은우 와이드핏 데님, 나일론 이지팬츠 외 6개 상품"
      />
      <ProjectDetailContainer>
        <Search
          total={SAMPLE_ROW_DATA.length}
          // label={{ search: "고객사 검색" }}
          dataList={{ searchCondition: [{ label: "2", value: 2 }] }}
          actions={{
            bottom: [
              { label: "선택 삭제", onClick: handleDeleteItem },
              {
                label: "전체 매핑 완료",
                onClick: handleMarkMappingDone,
                btnProps: {
                  disabled: true,
                },
              },
              { label: "엑셀파일 다운로드", onClick: handleDownloadExcel },
            ],
          }}
        />
        <ProjectTable />
      </ProjectDetailContainer>
    </>
  )
}

export default ProjectDetail
