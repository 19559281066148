import { Controller, useFormContext } from "react-hook-form"

import { Box } from "@mui/material"

import { CheckBox, Label } from "pages/Login/components/Checkbox/styles"
import { IFormValues } from "pages/Login/types"
import { ReactComponent as IcActiveCheckBox } from "assets/icons/ic_active_checkbox.svg"
import { ReactComponent as IcCheckBox } from "assets/icons/ic_inactive_checkbox.svg"

function Checkbox() {
  const { control } = useFormContext<IFormValues>()

  return (
    <>
      <Box>
        <Controller
          name="cookie"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Label
                label="아이디 저장"
                control={
                  <CheckBox
                    name="cookie"
                    disableRipple
                    color="primary"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    icon={<IcCheckBox />}
                    checkedIcon={<IcActiveCheckBox />}
                  />
                }
              />
            )
          }}
        />
      </Box>
    </>
  )
}

export default Checkbox
