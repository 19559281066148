import { useGetList } from "hooks/common"

import { MENU_CONFIG } from "configs/menu"
import { IPaymentListResponse } from "./types"

const useGetPaymentList = () => {
  const { data, isLoading, refetch, error, isFetching } =
    useGetList<IPaymentListResponse>({
      menu: MENU_CONFIG.customer.page,
      url: "/admin/payment/list",
    })

  return { data, isLoading, refetch, error, isFetching }
}

export default useGetPaymentList
