import { FormProvider, useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { Button, FormLabel, Grid, Typography } from "@mui/material"
import { useMenu } from "hooks/common"
import { Input } from "components"
import { PageHeader } from "layout"
import { FORM_OPTIONS, SAMPLE_VALUE } from "configs/form/customer"
import { MENU_CONFIG } from "configs/menu"

import {
  AvatarImg,
  CustomerActions,
  CustomerAvatar,
  CustomerAvatarBtn,
  CustomerEditContainer,
  CustomerEditGrid,
  CustomerForm,
  CustomerRow,
} from "./styles"
import { FormItem, IFormValues } from "./types"

const CustomerEdit = () => {
  const menu = useMenu(MENU_CONFIG.customer)
  const history = useHistory()
  const { id } = useParams<{ id?: string }>()
  const methods = useForm<IFormValues>({
    ...FORM_OPTIONS,
    defaultValues: id ? SAMPLE_VALUE.Customer : FORM_OPTIONS.defaultValues,
  })

  const listInputName: FormItem[] = [
    {
      label: "고객사명",
      name: "customerName",
      isRequired: true,
    },
    {
      label: "고객사 분류",
      name: "customerType",
      isRequired: true,
    },
    {
      label: "법인/사업자 등록번호",
      name: "registerNum",
    },
    {
      label: "대표자",
      name: "representative",
    },
    {
      label: "전화번호",
      name: "phoneNumber",
    },
    {
      label: "주소",
      name: "address",
    },
    {
      label: "담당자 이름",
      name: "contactName",
      isNeedSpacing: true,
    },
    { label: "담당자 연락처", name: "contact" },
    { label: "담당자 이메일", name: "contactEmail" },
  ]

  const handleGoBack = () => {
    if (id) {
      return history.goBack()
    }
    return history.push("/customer")
  }

  const onSubmit = (data: IFormValues) => {
    console.log(data)
    history.push("/customer")
  }

  if (id) {
    listInputName.splice(1, 0, {
      label: "고객 ID",
      name: "customerID",
      isReadOnly: true,
    })
  }

  return (
    <FormProvider {...methods}>
      <PageHeader
        text={id ? "고객사 정보 수정" : "신규 고객사 등록"}
        path="/customer"
      />
      <CustomerEditContainer
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <CustomerEditGrid container columnGap={2.5} flexWrap="nowrap">
          <CustomerAvatar item xs={3}>
            <AvatarImg />
            <CustomerAvatarBtn variant="text" disableRipple>
              로고 이미지 등록
            </CustomerAvatarBtn>
          </CustomerAvatar>
          <Grid item xs={9}>
            <CustomerForm>
              {listInputName.map((input, idx) => (
                <CustomerRow
                  container
                  key={idx}
                  sx={{ marginTop: input.isNeedSpacing ? "30px" : "10px" }}
                >
                  <Grid item xs={2}>
                    <FormLabel required={input.isRequired}>
                      {input.label}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={6}>
                    {input.isReadOnly ? (
                      <Typography pl="14px">
                        {(SAMPLE_VALUE.Customer as any)[input.name]}
                      </Typography>
                    ) : (
                      <Input name={input.name} fullWidth />
                    )}
                  </Grid>
                </CustomerRow>
              ))}
            </CustomerForm>
            <CustomerActions
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              columnGap="15px"
            >
              <Button variant="outlined" onClick={handleGoBack}>
                등록 취소
              </Button>
              <Button type="submit" variant="contained">
                등록 완료
              </Button>
            </CustomerActions>
          </Grid>
        </CustomerEditGrid>
      </CustomerEditContainer>
    </FormProvider>
  )
}

export default CustomerEdit
