import React from "react"

import { Route, RouteProps } from "react-router-dom"
import { Layout } from "layout"
import COOKIE_NAME from "configs/cookie/cookieNames"

export type RouteWrapperType = {
  component: React.ElementType
} & RouteProps

function RouteWrapper({ component: Component, ...rest }: RouteWrapperType) {
  localStorage.setItem(COOKIE_NAME.WEB_PATH, rest.location?.pathname || "/")

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

export default RouteWrapper
