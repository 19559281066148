import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { BrowserRouter } from "react-router-dom"

import { setLogIn } from "slices/loginSlice"
import { useAppSelector, useAppDispatch } from "hooks/redux"

import ScrollToTop from "components/ScrollToTop"
import PublicRouter from "routes/PublicRouter"
import PrivateRouter from "routes/Router"
import COOKIE_NAME from "configs/cookie/cookieNames"

export default function App() {
  const isLogin = useAppSelector((state) => state.login.isLogin)
  const [cookies] = useCookies([COOKIE_NAME.ACCESS_TOKEN])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (cookies?.accessToken) {
      dispatch(setLogIn(cookies.accessToken))
    }
  }, [cookies])

  return (
    <BrowserRouter>
      <ScrollToTop />
      {isLogin ? <PrivateRouter /> : <PublicRouter />}
    </BrowserRouter>
  )
}
