const SAMPLE_ROW_DATA = [
  {
    id: 1,
    name: "Cheese Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    id: 2,
    name: "Strawberry Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    id: 3,
    name: "Chocolate Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    id: 4,
    name: "Peanut Butter Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    id: 5,
    name: "Banana Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    id: 6,
    name: "White Chocolate Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    id: 7,
    name: "Fruits Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    id: 7,
    name: "Fruits Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    id: 8,
    name: "Fruits Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    id: 9,
    name: "Fruits Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    id: 10,
    name: "Fruits Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    id: 11,
    name: "Fruits Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
]

const SAMPLE_CHART_DATA = [
  {
    idx: 1,
    name: "Cheese Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    idx: 2,
    name: "Strawberry Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    idx: 3,
    name: "Chocolate Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
  {
    idx: 4,
    name: "Peanut Butter Cupcake",
    calories: 1111,
    fat: 5.5,
    carbs: 144,
    protein: 1.2,
    sortOrder: 1,
    regdate: "2020-02-02",
  },
]

const SAMPLE_DATA = {
  idx: 1,
  name: "Strawberry Chocolate Cupcake",
  category: "Cupcake",
  calories: 305,
  fat: 3.7,
  carbs: 67,
  protein: 4.3,
  quantity: 7,
  color: "white",
  description: "This is a Strawberry Chocolate Cupcake.",
  ingredients: "chocolate, strawberry, cheese",
  useYn: "Y",
  useYnText: "사용",
  viewYn: "Y",
  viewYnText: "노출",
  regDate: "2021-08-31",
}

const SAMPLE_DATA_DASHBOARD = [
  {
    division: "전체",
    divisionColor: "#192124",
    sum: "4,360 회",
    1: "100회",
    2: "130회",
    3: "80회",
    4: "150회",
    5: "150회",
    6: "150회",
    7: "150회",
    8: "150회",
  },
  {
    division: "S9 모델",
    divisionColor: "#008FFF",
    sum: "4,360 회",
    1: "100회",
    2: "130회",
    3: "80회",
    4: "150회",
    5: "150회",
    6: "150회",
    7: "150회",
    8: "150회",
  },
]

const SAMPLE_DATA_CUSTOMER = [
  {
    id: 1,
    customerCompanyName: "tootoo denim",
    customerCompanyImg: require("assets/images/customer_avatar.png"),
    customerCompanyCate: "의류",
    personChargeName: "서유진",
    personChargePhone: "010-1234-5678",
    personChargeEmail: "seo@blockodyssey.io",
    customerCompanyRegDate: "2023-02-22 13:51",
  },
  {
    id: 2,
    customerCompanyName: "tootoo denim",
    customerCompanyImg: require("assets/images/customer_avatar.png"),
    customerCompanyCate: "의류",
    personChargeName: "서유진",
    personChargePhone: "010-1234-5678",
    personChargeEmail: "seo@blockodyssey.io",
    customerCompanyRegDate: "2023-02-22 13:51",
  },
  {
    id: 3,
    customerCompanyName: "tootoo denim",
    customerCompanyImg: require("assets/images/customer_avatar.png"),
    customerCompanyCate: "의류",
    personChargeName: "서유진",
    personChargePhone: "010-1234-5678",
    personChargeEmail: "seo@blockodyssey.io",
    customerCompanyRegDate: "2023-02-22 13:51",
  },
  {
    id: 4,
    customerCompanyName: "tootoo denim",
    customerCompanyImg: require("assets/images/customer_avatar.png"),
    customerCompanyCate: "의류",
    personChargeName: "서유진",
    personChargePhone: "010-1234-5678",
    personChargeEmail: "seo@blockodyssey.io",
    customerCompanyRegDate: "2023-02-22 13:51",
  },
  {
    id: 5,
    customerCompanyName: "tootoo denim",
    customerCompanyImg: require("assets/images/customer_avatar.png"),
    customerCompanyCate: "의류",
    personChargeName: "서유진",
    personChargePhone: "010-1234-5678",
    personChargeEmail: "seo@blockodyssey.io",
    customerCompanyRegDate: "2023-02-22 13:51",
  },
  {
    id: 6,
    customerCompanyName: "tootoo denim",
    customerCompanyImg: require("assets/images/customer_avatar.png"),
    customerCompanyCate: "의류",
    personChargeName: "서유진",
    personChargePhone: "010-1234-5678",
    personChargeEmail: "seo@blockodyssey.io",
    customerCompanyRegDate: "2023-02-22 13:51",
  },
  {
    id: 7,
    customerCompanyName: "tootoo denim",
    customerCompanyImg: require("assets/images/customer_avatar.png"),
    customerCompanyCate: "의류",
    personChargeName: "서유진",
    personChargePhone: "010-1234-5678",
    personChargeEmail: "seo@blockodyssey.io",
    customerCompanyRegDate: "2023-02-22 13:51",
  },
  {
    id: 8,
    customerCompanyName: "tootoo denim",
    customerCompanyImg: require("assets/images/customer_avatar.png"),
    customerCompanyCate: "의류",
    personChargeName: "서유진",
    personChargePhone: "010-1234-5678",
    personChargeEmail: "seo@blockodyssey.io",
    customerCompanyRegDate: "2023-02-22 13:51",
  },
]

const SAMPLE_DATA_QR = [
  {
    id: 1,
    customerCompanyName: "tootoo denim",
    projectName: "차은우 와이드핏 데님, 나일론 이지팬츠 외 6개 상품",
    projectId: "T000001_01",
    QRIssueTotal: 3000,
    projectCreatedDate: "2023-02-21 12:15",
    mappingStatus: "완료",
    mappingDate: "2023-02-22 13:51",
    inspectStatus: "검수완료",
    inspectDate: "",
  },
  {
    id: 2,
    customerCompanyName: "IUOU",
    projectName: " 아유오유 소녀소녀 원피스, 스커트 외 2개 상품",
    projectId: "T000002_01",
    QRIssueTotal: 10,
    projectCreatedDate: "2023-02-20 12:15",
    mappingStatus: "미완료",
    mappingDate: "2023-02-21 09:01",
    inspectStatus: "검수취소",
    inspectDate: "2023-02-21 10:08",
  },
  {
    id: 3,
    customerCompanyName: "IUOU",
    projectName: "아유오유 소녀소녀 원피스, 스커트 외 2개 상품",
    projectId: "T000002_02",
    QRIssueTotal: 1000,
    projectCreatedDate: "2023-02-19 12:15",
    mappingStatus: "미완료",
    mappingDate: "2023-02-21 09:01",
    inspectStatus: "검수취소",
    inspectDate: "2023-02-21 10:12",
  },
  {
    id: 4,
    customerCompanyName: "tootoo denim",
    projectName: "차은우 와이드핏 데님, 나일론 이지팬츠 외 6개 상품",
    projectId: "T000001_01",
    QRIssueTotal: 3000,
    projectCreatedDate: "2023-02-21 12:15",
    mappingStatus: "완료",
    mappingDate: "2023-02-22 13:51",
    inspectStatus: "검수완료",
    inspectDate: "",
  },
  {
    id: 5,
    customerCompanyName: "IUOU",
    projectName: " 아유오유 소녀소녀 원피스, 스커트 외 2개 상품",
    projectId: "T000002_01",
    QRIssueTotal: 10,
    projectCreatedDate: "2023-02-20 12:15",
    mappingStatus: "미완료",
    mappingDate: "2023-02-21 09:01",
    inspectStatus: "검수취소",
    inspectDate: "2023-02-21 10:08",
  },
  {
    id: 6,
    customerCompanyName: "IUOU",
    projectName: "아유오유 소녀소녀 원피스, 스커트 외 2개 상품",
    projectId: "T000002_02",
    QRIssueTotal: 1000,
    projectCreatedDate: "2023-02-19 12:15",
    mappingStatus: "미완료",
    mappingDate: "2023-02-21 09:01",
    inspectStatus: "검수취소",
    inspectDate: "2023-02-21 10:12",
  },
  {
    id: 7,
    customerCompanyName: "tootoo denim",
    projectName: "차은우 와이드핏 데님, 나일론 이지팬츠 외 6개 상품",
    projectId: "T000001_01",
    QRIssueTotal: 3000,
    projectCreatedDate: "2023-02-21 12:15",
    mappingStatus: "완료",
    mappingDate: "2023-02-22 13:51",
    inspectStatus: "검수완료",
    inspectDate: "",
  },
  {
    id: 8,
    customerCompanyName: "IUOU",
    projectName: " 아유오유 소녀소녀 원피스, 스커트 외 2개 상품",
    projectId: "T000002_01",
    QRIssueTotal: 10,
    projectCreatedDate: "2023-02-20 12:15",
    mappingStatus: "미완료",
    mappingDate: "2023-02-21 09:01",
    inspectStatus: "검수취소",
    inspectDate: "2023-02-21 10:08",
  },
  {
    id: 9,
    customerCompanyName: "IUOU",
    projectName: "아유오유 소녀소녀 원피스, 스커트 외 2개 상품",
    projectId: "T000002_02",
    QRIssueTotal: 1000,
    projectCreatedDate: "2023-02-19 12:15",
    mappingStatus: "미완료",
    mappingDate: "2023-02-21 09:01",
    inspectStatus: "검수취소",
    inspectDate: "2023-02-21 10:12",
  },
]

const SAMPLE_DATA_PROJECT = [
  {
    id: 1,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    QRUrl: "tootoo.bo.io/00000004",
    QRImg: require("assets/images/img_qr.png"),
    QRCreatedDate: "2023-02-22 13:51",
  },
  {
    id: 2,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    QRUrl: "tootoo.bo.io/00000004",
    QRImg: require("assets/images/img_qr.png"),
    QRCreatedDate: "2023-02-22 13:51",
  },
  {
    id: 3,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    QRUrl: "tootoo.bo.io/00000004",
    QRImg: require("assets/images/img_qr.png"),
    QRCreatedDate: "2023-02-22 13:51",
  },
  {
    id: 4,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    QRUrl: "tootoo.bo.io/00000004",
    QRImg: require("assets/images/img_qr.png"),
    QRCreatedDate: "2023-02-22 13:51",
  },
  {
    id: 5,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    QRUrl: "tootoo.bo.io/00000004",
    QRImg: require("assets/images/img_qr.png"),
    QRCreatedDate: "2023-02-22 13:51",
  },
  {
    id: 6,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    QRUrl: "tootoo.bo.io/00000004",
    QRImg: require("assets/images/img_qr.png"),
    QRCreatedDate: "2023-02-22 13:51",
  },
  {
    id: 7,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    QRUrl: "tootoo.bo.io/00000004",
    QRImg: require("assets/images/img_qr.png"),
    QRCreatedDate: "2023-02-22 13:51",
  },
  {
    id: 8,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    QRUrl: "tootoo.bo.io/00000004",
    QRImg: require("assets/images/img_qr.png"),
    QRCreatedDate: "2023-02-22 13:51",
  },
  {
    id: 9,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    QRUrl: "tootoo.bo.io/00000004",
    QRImg: require("assets/images/img_qr.png"),
    QRCreatedDate: "2023-02-22 13:51",
  },
  {
    id: 10,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    QRUrl: "tootoo.bo.io/00000004",
    QRImg: require("assets/images/img_qr.png"),
    QRCreatedDate: "2023-02-22 13:51",
  },
]

const SAMPLE_DATA_NFT = [
  {
    id: 1,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    tokenId: "Token_01",
    ownerWalletId: "0x7eF5....c432",
    NFTPublicDate: "2023-02-22 13:51",
  },
  {
    id: 2,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    tokenId: "Token_02",
    ownerWalletId: "0x7eF5....c432",
    NFTPublicDate: "2023-02-22 13:51",
  },
  {
    id: 3,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    tokenId: "Token_03",
    ownerWalletId: "0x7eF5....c432",
    NFTPublicDate: "2023-02-22 13:51",
  },
  {
    id: 4,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    tokenId: "Token_04",
    ownerWalletId: "0x7eF5....c432",
    NFTPublicDate: "2023-02-22 13:51",
  },
  {
    id: 5,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    tokenId: "Token_05",
    ownerWalletId: "0x7eF5....c432",
    NFTPublicDate: "2023-02-22 13:51",
  },
  {
    id: 6,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    tokenId: "Token_06",
    ownerWalletId: "0x7eF5....c432",
    NFTPublicDate: "2023-02-22 13:51",
  },
  {
    id: 7,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    tokenId: "Token_07",
    ownerWalletId: "0x7eF5....c432",
    NFTPublicDate: "2023-02-22 13:51",
  },
  {
    id: 8,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    tokenId: "Token_08",
    ownerWalletId: "0x7eF5....c432",
    NFTPublicDate: "2023-02-22 13:51",
  },
  {
    id: 9,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    tokenId: "Token_09",
    ownerWalletId: "0x7eF5....c432",
    NFTPublicDate: "2023-02-22 13:51",
  },
  {
    id: 10,
    customerCompanyName: "tootoo denim",
    productImg: require("assets/images/img_product.png"),
    productName: "차은우 와이드핏 데님",
    detailView: "내용보기>",
    productId: "cd_04",
    QRId: "000000004",
    tokenId: "Token_10",
    ownerWalletId: "0x7eF5....c432",
    NFTPublicDate: "2023-02-22 13:51",
  },
]

export {
  SAMPLE_ROW_DATA,
  SAMPLE_CHART_DATA,
  SAMPLE_DATA,
  SAMPLE_DATA_DASHBOARD,
  SAMPLE_DATA_CUSTOMER,
  SAMPLE_DATA_QR,
  SAMPLE_DATA_PROJECT,
  SAMPLE_DATA_NFT,
}
