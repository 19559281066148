import { Box, styled } from "@mui/material"
import palette from "styles/theme/palette"
import { ITextarea } from "./types"

export const StyledTextareaContainer = styled(Box)(
  ({ theme, fullWidth }: any) => ({})
)

export const StyledTextarea = styled("textarea")<ITextarea>(
  ({ theme, fullWidth }) => ({
    resize: "none",
    outline: "none",
    background: theme.palette.common.white,
    width: fullWidth ? "100%" : "auto",
    border: `1px solid ${palette.border.main}`,
    padding: "8.5px 14px",
    borderRadius: 3,
    fontFamily: "Pretendard, san-serif",
    fontSize: 12,
  })
)
