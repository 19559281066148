import {
  StyledBox,
  StyledCopyright,
  StyledSwing,
} from "layout/SideBar/components/Copyright/styles"

export default function Copyright() {
  return (
    <StyledBox>
      <StyledSwing>SWING X BOATS</StyledSwing>
      <StyledCopyright>
        Copyright Ⓒ BlockOdyssey Corp.
        <br />
        All rights reserved
      </StyledCopyright>
    </StyledBox>
  )
}
