import ko from "date-fns/locale/ko"
import dayjs from "dayjs"
import { registerLocale } from "react-datepicker"
import { Grid, Typography } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import {
  StyledDatePicker,
  StyledEmptyBox,
  StyledTitle,
} from "components/SearchInputs/DatePicker/styles"
import { IDatePicker } from "components/SearchInputs/DatePicker/types"
import { ReactComponent as IconDate } from "assets/icons/ic_calendar.svg"

import { formatDate } from "utils/common"

import { helperMonthChange } from "utils/dateTime"
import "react-datepicker/dist/react-datepicker.css"

registerLocale("ko", ko)

function DatePicker({
  readOnly = false,
  dates = { startDate: null, endDate: null },
  term,
  onChange,
  maxDate,
}: IDatePicker) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      dateFormats={{ monthAndYear: "YYYY년 MMM" }}
      adapterLocale="ko"
    >
      <Grid item width={186}>
        <StyledTitle variant="h6">시작일</StyledTitle>
        <StyledDatePicker
          format="YYYY/MM/DD"
          value={dayjs(dates.startDate) || undefined}
          slots={{
            openPickerIcon: () => <IconDate />,
          }}
          onChange={(e) => {
            onChange("startDate", e ? formatDate(term, e) : null)
          }}
          maxDate={dayjs(maxDate)}
          onMonthChange={helperMonthChange}
        />
      </Grid>
      <Grid item paddingX="16px">
        <StyledEmptyBox />
        <Typography component="span" variant="dash">
          -
        </Typography>
      </Grid>
      <Grid item width={186}>
        <StyledTitle variant="h6">종료일</StyledTitle>
        <StyledDatePicker
          format="YYYY/MM/DD"
          value={dayjs(dates.endDate) || undefined}
          slots={{
            openPickerIcon: () => <IconDate />,
          }}
          onChange={(e) => {
            onChange("endDate", e ? formatDate(term, e) : null)
          }}
          maxDate={dayjs(maxDate)}
          onMonthChange={helperMonthChange}
        />
      </Grid>
    </LocalizationProvider>
  )
}

export default DatePicker
