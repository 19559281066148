enum PaymentState {
  FAIL = "FAIL",
  SUCCESS = "SUCCESS",
  REFUND = "REFUND",
  WAITING = "WATING",
}

export function mapPaymentStateToValue(key: string) {
  switch (key) {
    case PaymentState.FAIL:
      return "실패"
    case PaymentState.SUCCESS:
      return "성공"
    case PaymentState.REFUND:
      return "환불"
    case PaymentState.WAITING:
      return "대기중"
    default:
      return key
  }
}

export function isPaymentStateSuccess(key: string) {
  if (key === PaymentState.SUCCESS) {
    return true
  }

  return false
}

export function isRefundState(key: string) {
  switch (key) {
    case PaymentState.REFUND:
      return true
    default:
      return false
  }
}

export function isDisableRefundButton(
  key: string,
  vehicleId: string | undefined | null
) {
  switch (key) {
    case PaymentState.REFUND:
    case PaymentState.WAITING:
    case PaymentState.FAIL:
      return true
    case PaymentState.SUCCESS: {
      if (vehicleId && typeof vehicleId === "string") {
        return false
      }

      return true
    }
    default:
      return true
  }
}

export function mapVehicleToValue(key: string) {
  switch (key) {
    case "SCOOTER":
      return "킥보드"
    case "BIKE":
      return "자전거"
    case "MOPED":
      return "전기자전거"

    default:
      return "-"
  }
}
