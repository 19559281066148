/* eslint-disable import/prefer-default-export */
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledBox = styled(Box)(() => ({
  width: "100%",
  height: 100,
  padding: "0 30px 0 30px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}))

export const StyledSwing = styled(Typography)(({ theme: { palette } }) => ({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: "14.32px",
  color: palette.common.white,
  paddingBottom: 8,
  marginBottom: 18,
  borderBottomWidth: 0.5,
  borderBottomColor: "#383838",
  borderBottomStyle: "solid",
}))

export const StyledCopyright = styled(Typography)(({ theme: { palette } }) => ({
  width: "inherit",
  fontSize: 12,
  lineHeight: "14.32px",
  letterSpacing: "0px",
  color: palette.secondary.main,
}))
