/* eslint-disable import/prefer-default-export */
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { DatePicker } from "@mui/x-date-pickers"

export const StyledEmptyBox = styled(Box)(() => ({
  height: 27,
}))

export const StyledTitle = styled(Typography)(({ theme: { palette } }) => ({
  fontSize: 12,
  lineHeight: "14.32px",
  fontWeight: 400,
  marginBottom: 4,
  color: palette.text.label,
}))

export const StyledDatePicker = styled(DatePicker)(
  ({ theme: { palette } }) => ({
    width: 180,
    "& .MuiInputBase-input": {
      paddingTop: 12,
      paddingBottom: 12,
      fontSize: 13,
      color: palette.common.black,
      lineHeight: 16,
    },
    "& .MuiPickersCalendarHeader-root": {
      marginTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
  })
)
