import { Typography } from "@mui/material"

import { IErrorMessage } from "components/FormInputs/ErrorMessage/types"

function ErrorMessage({ text = "" }: IErrorMessage) {
  return (
    <Typography
      sx={{
        whiteSpace: "pre-wrap",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "14.4px",
        color: "#FF3B30",
      }}
      component="p"
      color="error"
      fontSize={10}
      variant="errorMessage"
    >
      {text}
    </Typography>
  )
}

export default ErrorMessage
