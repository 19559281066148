import React, { useEffect, useState } from "react"

import dayjs from "dayjs"
import { shallowEqual } from "react-redux"

import { Box, Button, Grid, SelectChangeEvent } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"

import {
  SearchType,
  setSearchFilter,
  setSearchFilters,
} from "slices/searchSlice"

import { useMenu } from "hooks/common"
import { useAppDispatch, useAppSelector } from "hooks/redux"
import { useSearch } from "hooks/search"

import {
  DateTermButton,
  SearchDatePicker,
  SearchField,
  SearchSelect,
  SubmitButton,
} from "components"
import {
  ISearch,
  SearchDatesType,
  SearchParamType,
} from "components/Search/types"

import { MENU_CONFIG } from "configs/menu"
import { DEFAULT_DATES } from "configs/search"
import {
  DATA_SEARCH_TYPE_OPTIONS,
  DATA_PAYMENT_STATE_OPTIONS,
} from "constants/data"

import theme from "styles/theme/search"
import selection from "styles/theme/selection"

import { PREV_29_DAYS, TODAY, handleDateClick } from "utils/common"
import { StyledForm, StyledLongerInputContainer, StyledTitle } from "./styles"

const marginLeft = {
  marginLeft: "8px",
}

const INITIAL_DATE = {
  startDate: PREV_29_DAYS,
  endDate: TODAY,
}

function Search({
  total = 0,
  dataList = {},
  label,
  actions,
  tabList,
}: ISearch) {
  const dispatch = useAppDispatch()
  const menu: SearchParamType = useAppSelector((state) => state.menu.page)
  const searchState = useAppSelector((state) => state.search, shallowEqual)

  const handleSearch = useSearch({ menu })

  const [searchCondition, setSearchCondition] = useState(
    DATA_SEARCH_TYPE_OPTIONS[0].value
  )
  const [paymentState, setPaymentState] = useState(
    DATA_PAYMENT_STATE_OPTIONS[0].value
  )
  const [keyword, setKeyword] = useState("")
  const [dates, setDates] = useState<SearchDatesType>(INITIAL_DATE)

  const handleSearchFilter = (obj: SearchType) => {
    // 새로 검색할 경우 페이지 번호 초기화하기
    if (+searchState.page > 1) {
      Object.assign(obj, { page: 1 })
    }

    // dateTerm 설정
    if (menu === "Dashboard") {
      obj.dashboardDateTerm = obj.dateTerm
      delete obj.dateTerm
    }

    dispatch(setSearchFilters(obj))
    handleSearch(obj)
  }

  // 검색 기간 (기간 검색 부분의 Date Picker) 변경
  const onDateChange = (name: string, value: string | null) => {
    setDates((prev) => ({ ...prev, [name]: value, dateTerm: 0 }))
    handleSearchFilter({ [name]: value as string })
  }

  // 검색 조건 (select) 변경
  const onSelect = (e: SelectChangeEvent<string | unknown>) => {
    const { name, value } = e.target
    if (name === "searchType") {
      setSearchCondition(value as string)
      return
    }
    if (name === "paymentState") {
      setPaymentState(value as string)
    }
  }

  // 조회 버튼 클릭
  const onSubmit = (
    e:
      | React.FormEvent<HTMLFormElement | HTMLDivElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault()
    const obj = {
      // ...searchState,
      // ...dates,
      searchCondition,
      paymentState,
      searchKeyword: keyword,
    }
    handleSearchFilter(obj)
    handleSearch(obj)
  }

  // 검색 키워드 변경
  const onKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setKeyword(e.target.value)

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmit(e)
    }
  }

  // 테이블 데이터 정렬하기
  const onSort = (e: SelectChangeEvent<string | unknown>) =>
    handleSearchFilter({ [e.target.name]: e.target.value })

  useEffect(() => {
    dispatch(setSearchFilter({ type: "searchType", value: "ALL" }))
    dispatch(setSearchFilter({ type: "paymentState", value: "ALL" }))
  }, [])

  const changeDates = ({ startDate, endDate }: typeof INITIAL_DATE) => {
    setDates({ startDate, endDate })
    handleSearchFilter({ startDate, endDate })
  }

  const handleFilterDate = (type?: string) => {
    const { endDate } = INITIAL_DATE
    let { startDate } = INITIAL_DATE
    switch (type) {
      case "today":
        startDate = dayjs().format("YYYY-MM-DD")
        break
      case "week":
        startDate = dayjs(endDate).subtract(7, "days").format("YYYY-MM-DD")
        break
      case "month":
        startDate = dayjs(endDate).subtract(30, "days").format("YYYY-MM-DD")

        break
      case "3_month":
        startDate = dayjs(endDate).subtract(90, "days").format("YYYY-MM-DD")
        break
      case "6_month":
        startDate = dayjs(endDate).subtract(180, "days").format("YYYY-MM-DD")
        break
      default:
        startDate = dayjs(endDate).subtract(30, "days").format("YYYY-MM-DD")
        break
    }
    changeDates({ startDate, endDate })
  }

  return (
    <ThemeProvider theme={selection}>
      <StyledForm component="form" marginBottom="10px">
        <StyledLongerInputContainer mb="21px">
          <Grid item>
            <StyledTitle>조회조건</StyledTitle>
            <SearchSelect
              name="searchType"
              onChange={onSelect}
              dataList={DATA_SEARCH_TYPE_OPTIONS}
              value={searchCondition}
            />
          </Grid>
          <Grid item sx={marginLeft}>
            <StyledTitle>검색어</StyledTitle>
            <SearchField
              searchCondition={searchCondition}
              searchKeyword={keyword}
              dataList={dataList?.searchCondition}
              onSelect={onSelect}
              onChange={onKeywordChange}
              onEnter={onEnter}
            />
          </Grid>
          <Grid item sx={marginLeft}>
            <StyledTitle>결제 여부</StyledTitle>
            <SearchSelect
              name="paymentState"
              dataList={DATA_PAYMENT_STATE_OPTIONS}
              onChange={onSelect}
              value={paymentState}
            />
          </Grid>
          <Grid
            item
            xs="auto"
            sx={{
              marginLeft: "12px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              onClick={onSubmit}
              sx={{
                width: 82,
              }}
            >
              조회
            </Button>
          </Grid>
        </StyledLongerInputContainer>

        <ThemeProvider theme={theme}>
          <StyledLongerInputContainer>
            <SearchDatePicker
              term="dailySlash"
              dates={dates}
              onChange={onDateChange}
              maxDate={TODAY}
            />
            <Box>
              <Grid container gap="4px" sx={marginLeft}>
                <SubmitButton
                  onClick={() => handleFilterDate("today")}
                  text="오늘"
                  variant="contained"
                />
                <SubmitButton
                  onClick={() => handleFilterDate("week")}
                  text="1주일"
                  variant="contained"
                />
                <SubmitButton
                  onClick={() => handleFilterDate("month")}
                  text="1개월"
                  variant="contained"
                />
                <SubmitButton
                  onClick={() => handleFilterDate("3_month")}
                  text="3개월"
                  variant="contained"
                />
                <SubmitButton
                  onClick={() => handleFilterDate("6_month")}
                  text="6개월"
                  variant="contained"
                />
                <SubmitButton
                  onClick={() => handleFilterDate()}
                  text="기간 초기화"
                  variant="contained"
                />
              </Grid>
            </Box>
          </StyledLongerInputContainer>
        </ThemeProvider>
      </StyledForm>
    </ThemeProvider>
  )
}

export default Search
