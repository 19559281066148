import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { VIETNAM_PHONE_REGEX } from "constants/validations"

export const SCHEMA = {
  Customer: yup.object().shape(
    {
      customerName: yup.string().max(20).required("중복된 이름입니다."),
      customerType: yup.string().required("중복된 이름입니다."),
      // registerNum: yup.number().max(10),
      registerNum: yup.number(),
      representative: yup.string(),
      // phoneNumber: yup.string().when("phoneNumber", {
      //   is: (value: any) => value,
      //   then: (rule) => rule.matches(VIETNAM_PHONE_REGEX, "Please input correct phone number.")
      // }),
      phoneNumber: yup.string(),
      address: yup.string(),
      contactName: yup.string(),
      contact: yup.string(),
      contactEmail: yup.string(),
    },
    [["phoneNumber", "phoneNumber"]]
  ),
}

export const DEFAULT_VALUE = {
  Customer: {
    customerName: "",
    customerType: "",
    registerNum: 0,
    representative: "",
    phoneNumber: "",
    address: "",
    contactName: "",
    contact: "",
    contactEmail: "",
  },
}

export const SAMPLE_VALUE = {
  Customer: {
    customerName: "tootoo denim",
    customerID: "T000001",
    customerType: "의류",
    registerNum: 11011100009876,
    representative: "서유진",
    phoneNumber: "(02) 712-3456",
    address: "서울특별시 중구 을지로 100 (을지로2가, 파인에비뉴 A동) ",
    contactName: "서유진 대표",
    contact: "010-1234-5678",
    contactEmail: "help@lorem.ipsum",
  },
}

export const FORM_OPTIONS = {
  resolver: yupResolver(SCHEMA.Customer),
  defaultValues: DEFAULT_VALUE.Customer,
}
