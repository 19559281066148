import { Link, useLocation, useParams } from "react-router-dom"
import { Avatar, Button, Grid, Typography } from "@mui/material"
import { PageHeader } from "layout"
import {
  NFTDetailActions,
  NFTDetailCard,
  NFTDetailContainer,
  NFTDetailProperty,
} from "./styles"
import { INFTDetail } from "./types"

const NFTDetail = () => {
  const location = useLocation()
  const isNFTPublic = !location.pathname.includes("pending")

  const NFTPublicInfos = isNFTPublic
    ? [
        {
          label: "토큰id",
          value: "Token_05",
        },
        {
          label: "소유자 지갑주소",
          value: "0x7eF57Fc4Ceb05cd823d5226cceD3bT2c9F7Ac432",
        },
        {
          label: "NFT 발행일시",
          value: "2023-02-21 12:15 ",
        },
      ]
    : []

  const NFTDetails: Record<string, INFTDetail> = {
    QRMappingInfos: {
      title: isNFTPublic ? "NFT 정보" : "NFT(상품)정보",
      desc: isNFTPublic
        ? "발행된 NFT 정보입니다."
        : "NFT 발행 시 하단 상품 정보로 NFT가 발행됩니다.",
      imageUrl: require("assets/images/img_product.png"),
      properties: [
        {
          label: "상품명(NFT명)",
          value: "차은우 와이드핏 데님",
        },
        {
          label: "상품설명(NFT 설명)",
          value:
            "코튼 100% 소재의 와이드핏 데님팬츠 스타일로 13~13.5oz의 원단을 사용하여 다른 데님보다 조금 중량이 더 나가며 두께감이 있어서 와이드 실루엣을 더 잘 살려줍니다. 다양한 상의에 모두 코디 가능하며 오리지널한 워싱 무드 연출을 위해 핸드 브러쉬 가공을 적용하여 자연스러운 데님 컬러가 돋보이는 것이 특징입니다",
        },
        {
          label: "브랜드명",
          value: "tootoo denim",
        },
        {
          label: "제품제조번호",
          value: "20231004",
        },
        ...NFTPublicInfos,
      ],
    },
    NFTInfos: {
      title: "QR 매핑 정보",
      desc: "",
      imageUrl: require("assets/images/img_qr.png"),
      properties: [
        {
          label: "상품 ID",
          value: "cd_04",
        },
        {
          label: "QR ID",
          value: "000000004",
        },
        {
          label: "QR URL",
          value: "tootoo.bo.io/00000004",
        },
        {
          label: "QR 생성일시",
          value: "2023-02-22 13:51",
        },
        {
          label: "검수 완료일시",
          value: "2023-02-23 10:01",
        },
      ],
      actions: null,
    },

    QRCodeDetails: {
      title: "QR 코드 상세",
      desc: "",
      imageUrl: "",
      properties: [
        {
          label: "프로젝트명",
          value:
            "[T000001_01] 차은우 와이드핏 데님, 나일론 이지팬츠 외 6개 상품",
        },
        {
          label: "Group ID",
          value: "T000001_01_001",
        },

        {
          label: "Group 이름",
          value: "차은우 와이드핏 데님",
        },

        {
          label: "Group 하위 QR 개수",
          value: "9개",
        },
      ],
      actions: null,
    },
  }

  return (
    <>
      <PageHeader
        text="NFT 상세"
        path="/nft"
        desc={
          isNFTPublic
            ? "[Token_05] 차은우 와이드핏 데님"
            : "[000000004] 차은우 와이드핏 데님"
        }
      />
      <NFTDetailContainer>
        {Object.values(NFTDetails).map(
          ({ title, desc, imageUrl, properties, actions }, didx) => (
            <NFTDetailCard key={title}>
              <Typography variant="h2" mb={desc ? undefined : 2.25}>
                {title}
              </Typography>
              {desc && <Typography mb={1.25}>{desc}</Typography>}
              <NFTDetailProperty container direction="column">
                <Grid container>
                  <Grid item xs={9}>
                    {properties.map((prop, idx) => (
                      <Grid
                        key={idx}
                        container
                        mt={didx === 0 && idx === 4 ? 3 : 0}
                      >
                        <Grid item xs={2}>
                          <Typography fontWeight={700}>{prop.label}</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography>{prop.value}</Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  {imageUrl && (
                    <Grid
                      item
                      xs={3}
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Avatar
                        sx={{ height: 120, width: 120 }}
                        src={imageUrl}
                        variant="square"
                      />
                    </Grid>
                  )}
                </Grid>
              </NFTDetailProperty>
              {actions && (
                <NFTDetailActions>
                  <Link to={`${location.pathname}/edit`}>
                    <Button
                      variant="outlined"
                      onClick={() => actions.onClick?.()}
                    >
                      {actions.name}
                    </Button>
                  </Link>
                </NFTDetailActions>
              )}
            </NFTDetailCard>
          )
        )}
      </NFTDetailContainer>
    </>
  )
}

export default NFTDetail
