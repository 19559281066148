import { useEffect } from "react"
import { useHistory } from "react-router"
import COOKIE_NAME from "configs/cookie/cookieNames"

export default function useInitPage() {
  const history = useHistory()

  useEffect(() => {
    const location = localStorage.getItem(COOKIE_NAME.WEB_PATH)

    if (location) {
      history.push({
        pathname: location,
      })
    }
  }, [])
}
