import { Box, Grid, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledModal = styled(Box)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  borderRadius: 16,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "hidden",
  width: 480,
}))

export const StyleCloseBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  padding: 10,
}))

export const StyledBodyBox = styled(Grid)(() => ({
  padding: "16px 24px",
}))

export const StyledTitle = styled(Typography)(({ theme: { palette } }) => ({
  fontSize: "12px",
  lineHeight: "14.32px",
  color: palette.common.black,
}))

export const StyledBoldTitle = styled(Typography)(({ theme: { palette } }) => ({
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "23.87px",
  marginRight: 6,
  color: palette.common.black,
}))

export const StyledTitleContent = styled(Grid)(({ theme: { palette } }) => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "16.71px",
  color: palette.common.black,
}))

export const StyledTitleTable = styled(Typography)(() => ({
  color: "#8B8B8B",
  fontSize: 13,
  fontWeight: 500,
  lineHeight: "16px",
  width: 80,
}))

export const StyledTextTable = styled(Typography)(() => ({
  color: "#383838",
  fontSize: 13,
  fontWeight: 400,
  lineHeight: "15.51px",
}))

export const StyledTitleInput = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "16.71px",
  color: "#000000",
  paddingTop: 20,
  paddingBottom: 8,
}))

export const StyledTitleInputError = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 12,
  lineHeight: "14.32px",
  color: "#FF3B30",
  display: "inline",
  marginLeft: 4,
}))

export const RowContainer = styled(Grid)(() => ({
  marginBottom: 2,
}))
