import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

export const SCHEMA = {
  Login: yup.object().shape({
    email: yup
      .string()
      .email("계정 정보가 일치하지 않습니다.")
      .required("아이디, 비밀번호 미일치 시 텍스트 표기"),
    password: yup.string().required("아이디, 비밀번호 미일치 시 텍스트 표기"),
    cookie: yup.string().notRequired(),
  }),
}

export const DEFAULT_VALUE = {
  Login: {
    email: "",
    password: "",
    cookie: false,
  },
}

export const FORM_OPTIONS = {
  resolver: yupResolver(SCHEMA.Login),
  defaultValues: DEFAULT_VALUE.Login,
}
